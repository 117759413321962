import { Button, Image, ThemeUICSSObject } from 'theme-ui';
import { useAppDispatch } from '@/hooks/store';
import { setShowModal } from '@/state/ui';
import Plus from '@/assets/images/Plus.svg';

const iconStyle: ThemeUICSSObject = {
  pl: '4',
};

const AddLocationButton = () => {
  const dispatch = useAppDispatch();
  const handleAddLocationPress = () => {
    dispatch(setShowModal('CUSTOM_PRICING_ADD_LOCATION'));
  };

  return (
    <Button onClick={handleAddLocationPress} variant="btn_icon_lt">
      Add Location
      <Image src={Plus} sx={iconStyle} />
    </Button>
  );
};

export default AddLocationButton;
