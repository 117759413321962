import { ThemeUICSSObject } from 'theme-ui';
import { useAppSelector } from '@/hooks/store';
import ItemCustomPricingTableDataRow from './ItemCustomPricingTableDataRow';
import { selectCustomPriceInputRows } from '@/state/item/selectors';
import ItemPricingTableHeaderColumn from './ItemPricingTableHeaderColumn';
import { selectEditMode } from '@/state/ui/selectors';

const tableStyle: ThemeUICSSObject = {
  width: '100%',
  color: '#615E54',
  border: '1px solid',
  borderColor: 'secondaryBackgroundDisabled',
  borderCollapse: 'collapse',
};

const tableHeaderStyle: ThemeUICSSObject = {
  verticalAlign: 'top',
  textAlign: 'left',
  bg: 'backgroundWeak',
};

interface HeaderData {
  rowOneText: string | null;
  rowTwoText: string | null;
  questionIcon: boolean;
  thStyle: ThemeUICSSObject;
}

const headerData: HeaderData[] = [
  {
    rowOneText: 'Business Name',
    rowTwoText: null,
    questionIcon: false,
    thStyle: { pl: '2', width: '25%' },
  },
  {
    rowOneText: 'Warehouse',
    rowTwoText: null,
    questionIcon: false,
    thStyle: { width: '12%' },
  },
  {
    rowOneText: 'Purchase Price',
    rowTwoText: 'in Sales Unit',
    questionIcon: true,
    thStyle: { width: '13%', textAlign: 'center' },
  },
  {
    rowOneText: 'Landed Cost',
    rowTwoText: 'in Sales Unit',
    questionIcon: true,
    thStyle: { width: '13%', textAlign: 'center' },
  },
  {
    rowOneText: 'Margin %',
    rowTwoText: null,
    questionIcon: true,
    thStyle: { width: '13%', textAlign: 'center' },
  },
  {
    rowOneText: 'Custom Price',
    rowTwoText: null,
    questionIcon: true,
    thStyle: { width: '13%', textAlign: 'center' },
  },
  {
    rowOneText: 'Price Rule Id',
    rowTwoText: null,
    questionIcon: true,
    thStyle: { width: '8%', textAlign: 'center' },
  },
  {
    rowOneText: null,
    rowTwoText: null,
    questionIcon: false,
    thStyle: { width: '3%' },
  },
];

const ItemCustomPricingTable = () => {
  const customPriceInputRows = useAppSelector(selectCustomPriceInputRows);
  const editMode = useAppSelector(selectEditMode);

  return (
    <table sx={tableStyle}>
      <thead sx={{ height: '54px', bg: 'backgroundWeak' }}>
        <tr sx={tableHeaderStyle}>
          {headerData.map(
            (column, idx) =>
              (column.rowOneText || editMode) && (
                <ItemPricingTableHeaderColumn key={idx} {...column} />
              ),
          )}
        </tr>
      </thead>
      <tbody>
        {customPriceInputRows.map((row, idx) => (
          <ItemCustomPricingTableDataRow key={idx} {...row} />
        ))}
      </tbody>
    </table>
  );
};

export default ItemCustomPricingTable;
