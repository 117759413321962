import { hot } from 'react-hot-loader/root';
import { Suspense } from 'react';
import { Provider as UrqlProvider } from 'urql';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import AdminUserLoginChecker from './components/AdminUserLoginChecker';
import GlobalStyles from './GlobalStyles';
import PricingController from './components/PricingController';
import DevelopmentEnvironmentBanner from '@/components/DevelopmentEnvironmentBanner';
import { client } from './graphql/client';

const App = () => {
  return (
    <>
      <GlobalStyles />
      <AdminUserLoginChecker>
        <UrqlProvider value={client}>
          <Suspense fallback={<div>Loading...</div>}>
            <DevelopmentEnvironmentBanner />
            <Router>
              <Switch>
                {/* Automatically forces to '/items/:id' path on `npm run start`. 
                TODO: Find a better way to do this. */}
                {process.env.NODE_ENV === 'development' && (
                  <Redirect
                    exact
                    from="/"
                    to="/items/09eeae8c-688a-4d00-8113-23ee708b6985"
                  />
                )}
                <Route path="/items/:id" component={PricingController} />
              </Switch>
            </Router>
          </Suspense>
        </UrqlProvider>
      </AdminUserLoginChecker>
    </>
  );
};

export default hot(App);
