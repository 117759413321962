import { Money } from '@/generated/graphql-operations';

export const formattedPrice = (
  purchasePrice: Pick<Money, 'cents' | 'currency'>,
): string => {
  const { currency, cents } = purchasePrice;
  let priceAmount = cents;
  let locale = '';
  if (purchasePrice.currency === 'USD') {
    locale = 'en-US';
    // toFixed rounds up when rounding to 2 decimal places
    priceAmount = Number(priceAmount.toFixed(3).slice(0, -1));
  }
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(priceAmount);
};

export const calculateVolume = (
  length: number | undefined,
  width: number | undefined,
  height: number | undefined,
): number | undefined => {
  if (length && width && height) {
    return length * width * height;
  } else {
    return undefined;
  }
};

export const calculateDimensionalWeight = (
  length: number | undefined,
  width: number | undefined,
  height: number | undefined,
  dimensionalFactor: number | undefined,
  // eslint-disable-next-line max-params
): string | undefined => {
  if (
    length !== undefined &&
    width !== undefined &&
    height !== undefined &&
    dimensionalFactor !== undefined
  ) {
    return (
      Math.round((length * width * height) / dimensionalFactor).toString() +
        ' lbs' ?? ''
    );
  } else {
    return '';
  }
};
