/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Notifier } from '@airbrake/browser';

let airbrake: Notifier | null = null;

export const initErrorReporter = () => {
  airbrake = new Notifier({
    projectId: parseInt(window.env.AIRBRAKE_PROJECT_ID, 10),
    projectKey: window.env.AIRBRAKE_PROJECT_KEY,
  });

  airbrake.addFilter(notice => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    notice.context.environment = window.env.SERVICE_ENV;
    return notice;
  });
};

export const notify = async (error: any, info: any = null): Promise<any> => {
  // Send error to Airbrake
  return await (airbrake
    ? airbrake.notify({
        error,
        params: { info },
      })
    : Promise.resolve());
};
