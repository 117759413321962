import { Box, Flex, Text, ThemeUICSSObject } from 'theme-ui';
import { TOP_OFFSET } from './DevelopmentEnvironmentBanner';
import { HEADER_HEIGHT } from './Header';
import { RefObject, useState } from 'react';

export const SIDEBAR_WIDTH = 15;
const sidebarStyle: ThemeUICSSObject = {
  width: `${SIDEBAR_WIDTH}%`,
  borderRight: '2px solid',
  borderRightColor: 'backgroundWeak',
  position: 'fixed',
  height: '100%',
  top: `${TOP_OFFSET + HEADER_HEIGHT}px`,
  pl: '2%',
  pt: '6',
};

const sidebarLinkStyle: ThemeUICSSObject = {
  variant: 'text.sidebarLink',
  color: 'secondarySidebarText',
  pl: '2',
  mb: '2',
};

const sidebarLinkActiveStyle: ThemeUICSSObject = {
  ...sidebarLinkStyle,
  color: 'text',
};

const sidebarLinkPrimaryStyle: ThemeUICSSObject = {
  variant: 'text.sidebarLinkPrimary',
  color: 'secondarySidebarText',
  mb: '2',
};

const sidebarLinkPrimaryActiveStyle: ThemeUICSSObject = {
  ...sidebarLinkPrimaryStyle,
  color: 'text',
};

interface NavigationSidebarProps {
  showSidebar: boolean;
  salesPricingRef: RefObject<HTMLDivElement>;
  primaryInfoRef: RefObject<HTMLDivElement>;
  warehousePriceRef: RefObject<HTMLDivElement>;
  customPriceRef: RefObject<HTMLDivElement>;
}

const NavigationSidebar = ({
  showSidebar,
  salesPricingRef,
  primaryInfoRef,
  warehousePriceRef,
  customPriceRef,
}: NavigationSidebarProps) => {
  const [toggle, setToggle] = useState(salesPricingRef);
  const handleClick = (ref: RefObject<HTMLDivElement>) => {
    window.scrollTo({
      top: (ref.current?.offsetTop || 0) - 120,
      behavior: 'smooth',
    });
    setToggle(ref);
  };

  return showSidebar ? (
    <aside>
      <Box sx={sidebarStyle}>
        <Flex sx={{ flexDirection: 'column' }}>
          <Text
            onClick={() => handleClick(salesPricingRef)}
            sx={
              toggle === salesPricingRef
                ? sidebarLinkPrimaryActiveStyle
                : sidebarLinkPrimaryStyle
            }>
            Sales/Pricing
          </Text>
          <Text
            onClick={() => handleClick(primaryInfoRef)}
            sx={
              toggle === primaryInfoRef
                ? sidebarLinkActiveStyle
                : sidebarLinkStyle
            }>
            Primary Information
          </Text>
          <Text
            onClick={() => handleClick(warehousePriceRef)}
            sx={
              toggle === warehousePriceRef
                ? sidebarLinkPrimaryActiveStyle
                : sidebarLinkPrimaryStyle
            }>
            Warehouse Pricing
          </Text>
          <Text
            onClick={() => handleClick(customPriceRef)}
            sx={
              toggle === customPriceRef
                ? sidebarLinkPrimaryActiveStyle
                : sidebarLinkPrimaryStyle
            }>
            Custom Pricing
          </Text>
        </Flex>
      </Box>
    </aside>
  ) : (
    <></>
  );
};

export default NavigationSidebar;
