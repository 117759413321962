import { ThemeUICSSObject } from 'theme-ui';

const modalPopCSS: ThemeUICSSObject = {
  zIndex: '999',
  width: '100%',
  height: '100%',
  position: 'fixed',
  left: '0',
  top: '0',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
};

const modalContentPopCSS: ThemeUICSSObject = {
  backgroundColor: 'background',
  zIndex: '999',
  margin: 'auto',
  width: '100%',
  height: '100%',
};

const modalOverlayCSS: ThemeUICSSObject = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  zIndex: '99',
  backgroundColor: 'text',
  opacity: '0.5',
};

interface ModalProps {
  modalStyle: ThemeUICSSObject;
  children?: JSX.Element | JSX.Element[];
}

const ModalStyleWrapper = ({ modalStyle, children }: ModalProps) => {
  return (
    <>
      <div sx={modalPopCSS}>
        <div sx={{ ...modalContentPopCSS, ...modalStyle }}>{children}</div>
      </div>
      <div sx={modalOverlayCSS} />
    </>
  );
};

export default ModalStyleWrapper;
