// devToolsExchange should come first: https://github.com/FormidableLabs/urql-devtools-exchange#usage

import { devtoolsExchange } from '@urql/devtools';
import { createClient, defaultExchanges } from 'urql';

// Default Exchanges are dedupExchange, cachExchange, fetchExchange: https://formidable.com/open-source/urql/docs/architecture/
const createClientWithToken = (token: string) => {
  return createClient({
    url: window.env.GRAPHQL_ENDPOINT,
    exchanges: [devtoolsExchange, ...defaultExchanges],
    fetchOptions: () => ({
      headers: {
        Authorization: `Bearer ${token}`,
        'apollographql-client-name': window.env.GRAPHQL_CLIENT_NAME,
        'apollographql-client-version': '1.0.0',
      },
      credentials: 'include',
    }),
  });
};

// TODO: Get token through other means than local storage
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const client = createClientWithToken(localStorage.getItem('authToken')!);
