import {
  Box,
  Button,
  Flex,
  Image,
  Paragraph,
  Text,
  Link as ATag,
  ThemeUICSSObject,
} from 'theme-ui';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { formattedPrice } from '@/utils/readonly';
import { priceOutput } from '@/utils/PricingIO';
import {
  InventoryItem,
  NonInventoryItem,
} from '@/generated/graphql-operations';
import { setEditMode } from '@/state/ui';
import EditIcon from '@/assets/images/EditIcon.svg';
import { selectEditMode } from '@/state/ui/selectors';
import { selectItemData } from '@/state/item/selectors';

const topContainerStyle: ThemeUICSSObject = {
  height: '48px',
  justifyContent: 'space-between',
};

const flexStyle: ThemeUICSSObject = {
  my: '0',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const disabledNetsuiteLink: ThemeUICSSObject = {
  textDecoration: 'none',
  color: 'secondaryDisabled',
  variant: 'text.labelLg',
  cursor: 'not-allowed',
};

const LabelStyles: ThemeUICSSObject = {
  padding: 1,
  backgroundColor: 'primaryBackgroundWeak',
  borderRadius: 1,
  variant: 'text.labelCapsSm',
};

const horizontalLineStyle: ThemeUICSSObject = {
  borderBottom: '1px solid',
  borderBottomColor: 'secondaryBackgroundDisabled',
  marginY: 3,
};

const ItemDetailsHeader = () => {
  const dispatch = useAppDispatch();
  const editMode = useAppSelector(selectEditMode);
  const item = useAppSelector(selectItemData);

  const handlePressEditButton = () => {
    dispatch(setEditMode(true));
  };

  const response = item as InventoryItem | NonInventoryItem | undefined;

  const netsuiteUrl = response?.erpRecordUrl ? response.erpRecordUrl : null;

  const catalogStatusText =
    item?.catalogStatus === 'IN_CATALOG' ? 'In Catalog' : 'Not In Catalog';

  // TODO IMPORTANT:
  // I needed to add as Date here in order to resolve lint err.
  // Not 100% on why updatedAt is defined as any in the schema
  // Any why IMS didn't encoutner this lint err
  const updatedAtDate = new Date(item?.updatedAt as Date).toLocaleString(
    'default',
    {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      timeZone: 'UTC',
    },
  );

  const purchasePrice = item?.purchasePrice
    ? formattedPrice(priceOutput(item.purchasePrice))
    : null;

  return (
    <>
      <Flex sx={topContainerStyle}>
        <Text sx={{ variant: 'text.headingMd' }}>Sales/Pricing</Text>
        {/* Render edit button after data has loaded */}
        {!editMode && item !== undefined && (
          <Button onClick={handlePressEditButton} variant="btn_icon">
            Edit
            <Image src={EditIcon} sx={{ pl: '1' }} />
          </Button>
        )}
      </Flex>
      <Box sx={horizontalLineStyle} />
      <Box sx={{ my: '1' }}>
        <Flex sx={flexStyle}>
          <Box>
            {item?.imgUrl && (
              <Image
                src={item.imgUrl}
                sx={{ width: '60px', height: '60px', borderRadius: 1 }}
              />
            )}
            <Box sx={{ display: 'inline-block', paddingLeft: 5 }}>
              <Text sx={LabelStyles}>{catalogStatusText}</Text>
              <Paragraph sx={{ paddingY: 1, variant: 'text.labelLg' }}>
                {item?.name}
              </Paragraph>
              <Box
                sx={{ variant: 'text.paragraphMd', color: 'secondaryLabel' }}>
                <Text>{item?.saleUnit.name}</Text>
                <Text sx={{ paddingX: 2 }}>{item?.saleUnit.unitType}</Text>
                {item?.__typename === 'InventoryItem' && (
                  <Text>{purchasePrice}</Text>
                )}
              </Box>
            </Box>
          </Box>
          <Box sx={{ variant: 'text.paragraphMd', textAlign: 'right' }}>
            {netsuiteUrl ? (
              <ATag
                href={netsuiteUrl}
                target="_blank"
                sx={{ variant: 'styles.a', textDecoration: 'underline' }}>
                Netsuite Link
              </ATag>
            ) : (
              <Text sx={disabledNetsuiteLink}> Netsuite Link</Text>
            )}
            <Paragraph sx={{ color: 'secondaryLabel' }}>Updated</Paragraph>
            <Paragraph sx={{ paddingY: 1 }}>{updatedAtDate}</Paragraph>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default ItemDetailsHeader;
