import { ChangeEvent } from 'react';

/* NOTE: Currently 10.445 would become 10.44 but this will
 * not impact the accurracy of values submitted in mutation call */
export const displayMargin = (
  landedCost: number | undefined | null,
  salePrice: number | undefined | null,
): [string, string] => {
  if (landedCost && salePrice) {
    const marginPercent = (
      precisionMarginPercent(landedCost, salePrice) * 100
    ).toFixed(2);
    const marginDollars = ((salePrice - landedCost) / 100).toFixed(2);
    return [`${marginPercent}%`, `$${marginDollars}`];
  }
  return ['--', '--'];
};

export const precisionMarginPercent = (
  landedCost: number | null,
  salePrice: number | null,
): number => {
  if (landedCost && salePrice) {
    return (salePrice - landedCost) / salePrice;
  }
  return 0;
};

export const extractMarginPercentFromInput = (
  marginPercent: string,
): number => {
  // Remove any non-digit characters except '.'
  const pattern = /[^0-9.]/g;
  const marginPercentRaw = marginPercent.replaceAll(pattern, '');
  // In the event that marginPercentRaw is empty string, return 0
  return marginPercentRaw !== '' && marginPercentRaw !== '.'
    ? parseFloat(marginPercentRaw) / 100
    : 0;
};

export const calculatePurchasePriceCentsBySalesUnit = (
  purchasePriceCents: number,
  purchaseUnitConversionRate: number | null,
  salesUnitConversionRate: number,
): number => {
  /* Noninventory items currently do not have purchase unit data
   * Return purchase price cents for noninventory items */
  if (purchaseUnitConversionRate && salesUnitConversionRate) {
    return Math.round(
      (purchasePriceCents / purchaseUnitConversionRate) *
        salesUnitConversionRate,
    );
  }
  return purchasePriceCents;
};

export const calculateSalePrice = (
  landedCostCents: number,
  marginPercentFloat: number,
): [string, number] => {
  const salePriceCents = Math.round(landedCostCents / (1 - marginPercentFloat));
  return [centsToDollars(salePriceCents), salePriceCents]; // ex: ['$27.50', 2750]
};

export const centsToDollars = (cents: number | undefined | null): string => {
  if (cents === undefined || cents === null) {
    return '--';
  }
  return '$' + (cents / 100).toFixed(2);
};

export const dollarsToCents = (dollars: string): number => {
  const dollarsWrapped = checkAndFormatDollarInput(dollars);
  // Remove any non-digit characters from string and parse into integer
  const pattern = /\D/g;
  const centsString = dollarsWrapped.replaceAll(pattern, '');
  return parseInt(centsString);
};

export const checkAndFormatDollarInput = (dollars: string): string => {
  if (!dollars.trim() || dollars === '$') {
    return '$0.00';
  } else if (dollars === '--') {
    return dollars;
  }
  // If '$' not present, then prepend on blur ~ for UX purposes
  let dollarsWrapped = dollars;
  if (dollarsWrapped.charAt(0) !== '$') {
    dollarsWrapped = '$' + dollarsWrapped;
  }
  /* If digit not present on left-side decimal place,
   * then insert '0' at first index ~ for UX purposes */
  if (dollarsWrapped.charAt(1) === '.') {
    dollarsWrapped = dollarsWrapped.slice(0, 1) + '0' + dollarsWrapped.slice(1);
  }
  /* Ensure exact cents count even if no decimal
   * or less than two digits included to right of decimal.
   * Important for conversion to cents value
   * and improves UX on blur*/
  const lastElement = dollarsWrapped.length - 1;
  const secondToLastElement = dollarsWrapped.length - 2;
  if (dollarsWrapped.charAt(lastElement) === '.') {
    return dollarsWrapped + '00';
  } else if (dollarsWrapped.charAt(secondToLastElement) === '.') {
    return dollarsWrapped + '0';
  } else if (!dollarsWrapped.includes('.')) {
    return dollarsWrapped + '.00';
  }
  return dollarsWrapped;
};

export const checkAndFormatPercentInput = (percentInput: string): string => {
  /* This function ensures consistent formatting for UX purposes
   * does not have bearing on what is calculated or saved */
  if (percentInput === '--') {
    return '--';
  }

  let percentInputWrapped = percentInput;
  const pattern = /^0{0,2}\.?0{0,2}%?$/g;
  const basicallyZero = percentInput.match(pattern);
  if (basicallyZero) {
    return '0.00%';
  } else if (!percentInput.includes('.')) {
    const lastCharacter = percentInputWrapped.charAt(
      percentInputWrapped.length - 1,
    );
    lastCharacter === '%'
      ? (percentInputWrapped = percentInputWrapped.replace('%', '.00%'))
      : (percentInputWrapped += '.00%');
  }
  return percentInputWrapped;
};

export const validateDollarInput = (value: string): RegExpMatchArray | null => {
  // Users can only enter numbers and add no more than 2 decimal places
  const pattern = /^\$?\d*\.?\d{0,2}$/g;
  return value.match(pattern);
};

export const validateMarginPercentInput = (
  value: string,
): RegExpMatchArray | null => {
  // Users cannot enter 100% or greater and add no more than two decimal places
  const pattern = /^-?\d{0,2}(?:\.\d{0,2})?%?$/g;
  return value.match(pattern);
};

export const createChangeEventPayload = (
  event: ChangeEvent<HTMLInputElement>,
  locationOrWarehouseId: string,
) => {
  const { name: inputType, value } = event.currentTarget;
  /* Destructure essential data from event object and pass as new object.
   * Prevents putting non-serialized values in redux store */
  const eventReduced = { inputType, value };
  return { uuid: locationOrWarehouseId, eventReduced };
};
