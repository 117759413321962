import { Box } from 'theme-ui';
import ItemPricingHeader from './ItemPricingHeader';
import ItemPricingTable from './ItemPricingTable';
import { RefObject } from 'react';

interface ItemPriceProps {
  warehousePriceRef: RefObject<HTMLDivElement>;
}

const ItemPricing = ({ warehousePriceRef }: ItemPriceProps) => {
  return (
    <Box ref={warehousePriceRef}>
      <ItemPricingHeader />
      <ItemPricingTable />
    </Box>
  );
};

export default ItemPricing;
