import { Box, Container, ThemeUICSSObject } from 'theme-ui';

import ItemDetailsHeader from './ItemDetailsHeader';
import ItemDetailsPrimaryInformation from './ItemDetailsPrimaryInformation';
import { RefObject } from 'react';

const containerStyle: ThemeUICSSObject = {
  my: '6',
};

interface ItemDetailsProps {
  salesPricingRef: RefObject<HTMLDivElement>;
  primaryInfoRef: RefObject<HTMLDivElement>;
}

const ItemDetails = ({ salesPricingRef, primaryInfoRef }: ItemDetailsProps) => {
  return (
    <Container sx={containerStyle} ref={salesPricingRef}>
      <Box>
        <ItemDetailsHeader />
        <ItemDetailsPrimaryInformation primaryInfoRef={primaryInfoRef} />
      </Box>
    </Container>
  );
};

export default ItemDetails;
