import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  InputRowPayload,
  WarehousePriceInputRow,
  ItemState,
  CustomPriceInputRow,
  LocationSearchResult,
} from './types';
import {
  InventoryItem,
  NonInventoryItem,
  PricingGetItemForEditQuery,
  PricingGetLocationsFromSearchQuery,
  PricingUpdateItemPricesMutation,
  Currency,
  ItemLocationCustomPrice,
} from '@/generated/graphql-operations';
import {
  getLocationsFromSearch,
  mutateItemPrices,
  queryItemById,
} from './operations';
import { OperationResult, UseMutationState } from 'urql';
import {
  calculateSalePrice,
  centsToDollars,
  checkAndFormatDollarInput,
  checkAndFormatPercentInput,
  displayMargin,
  dollarsToCents,
  extractMarginPercentFromInput,
  precisionMarginPercent,
  validateDollarInput,
  validateMarginPercentInput,
} from '@/utils/CalculatorUtils';
import {
  shapeInitialCustomPriceInputRows,
  shapeInitialWarehousePriceInputRows,
} from '@/utils/PricingIO';
import { notify } from '@/services/errorReporter';

const initialState: ItemState = {
  itemResponseData: null,
  warehousePriceInputRows: [],
  isLoading: false,
  isSaving: false,
  isSearching: false,
  error: null,
  warehouses: null,
  customPriceLocationSearchResults: [],
  customPriceInputRows: [],
};

export const itemSlice = createSlice({
  name: 'item',
  initialState,
  reducers: {
    setWarehousePriceInputRows: (
      state,
      action: PayloadAction<WarehousePriceInputRow[]>,
    ) => {
      state.warehousePriceInputRows = action.payload;
    },
    resetWarehousePriceInputRows: state => {
      if (state.warehousePriceInputRows !== null) {
        for (const row of state.warehousePriceInputRows) {
          if (row.inputRowChanged === true) {
            row.newLandedCostCents =
              row.currentLandedCostCents ?? row.purchasePriceCents;
            row.newSalePriceCents = row.currentSalePriceCents ?? null;
            row.newLandedCost = centsToDollars(row.newLandedCostCents);
            // On discard, if cents value is null, then set newSalePrice to empty input
            row.newSalePrice =
              row.newSalePriceCents !== null
                ? centsToDollars(row.currentSalePriceCents)
                : '';
            const [newMarginPercent, newMarginDollars] = displayMargin(
              row.newLandedCostCents,
              row.newSalePriceCents,
            );
            row.newMarginPercent = newMarginPercent;
            row.newMarginDollars = newMarginDollars;
            row.newMarginCents = dollarsToCents(newMarginDollars);
            row.inputRowChanged = false;
          }
        }
      }
    },
    setWarehousePriceInputRowOnChange: (
      state,
      action: PayloadAction<InputRowPayload>,
    ) => {
      const inputRow = state.warehousePriceInputRows?.find(
        row => row.warehouseID === action.payload.uuid,
      );
      if (inputRow) {
        const { inputType, value } = action.payload.eventReduced;
        switch (inputType) {
          case 'newLandedCost': {
            const validated = validateDollarInput(value);
            if (validated) {
              inputRow.inputRowChanged = true;
              inputRow.newLandedCost = value;
              inputRow.newLandedCostCents = dollarsToCents(value);
              // Calculate and update margin values
              const [newMarginPercent, newMarginDollars] = displayMargin(
                inputRow.newLandedCostCents,
                inputRow.newSalePriceCents,
              );
              inputRow.newMarginPercent = newMarginPercent;
              inputRow.newMarginDollars = newMarginDollars;
              inputRow.newMarginPercentFloat = precisionMarginPercent(
                inputRow.newLandedCostCents,
                inputRow.newSalePriceCents,
              );
              inputRow.newMarginCents = dollarsToCents(
                inputRow.newMarginDollars,
              );
            }
            break;
          }
          case 'newMarginPercent': {
            const validated = validateMarginPercentInput(value);
            if (validated) {
              inputRow.inputRowChanged = true;
              inputRow.newMarginPercent = value;
              inputRow.newMarginPercentFloat =
                extractMarginPercentFromInput(value);
              // Calculate and update sale price values
              const [newSalePriceDollars, newSalePriceCents] =
                calculateSalePrice(
                  inputRow.newLandedCostCents,
                  inputRow.newMarginPercentFloat,
                );
              inputRow.newSalePrice = newSalePriceDollars;
              inputRow.newSalePriceCents = newSalePriceCents;
              // Calculate and update margin dollars
              inputRow.newMarginDollars = displayMargin(
                inputRow.newLandedCostCents,
                inputRow.newSalePriceCents,
              )[1];
            }
            break;
          }
          case 'newMarginDollars': {
            const validated = validateDollarInput(value);
            if (validated) {
              inputRow.inputRowChanged = true;
              inputRow.newMarginDollars = value;
              inputRow.newMarginCents = dollarsToCents(value);
              // Calculate and update sale price values
              inputRow.newSalePriceCents =
                inputRow.newLandedCostCents + inputRow.newMarginCents;
              inputRow.newSalePrice = centsToDollars(
                inputRow.newSalePriceCents,
              );
              // Calculate and update margin percent values
              inputRow.newMarginPercentFloat = precisionMarginPercent(
                inputRow.newLandedCostCents,
                inputRow.newSalePriceCents,
              );
              inputRow.newMarginPercent = displayMargin(
                inputRow.newLandedCostCents,
                inputRow.newSalePriceCents,
              )[0];
            }
            break;
          }
          case 'newSalePrice': {
            const validated = validateDollarInput(value);
            if (validated) {
              inputRow.inputRowChanged = true;
              inputRow.newSalePrice = value;
              inputRow.newSalePriceCents = value.trim()
                ? dollarsToCents(value)
                : null;
              // Calculate and update margin percent and margin dollars
              inputRow.newMarginPercentFloat = precisionMarginPercent(
                inputRow.newLandedCostCents,
                inputRow.newSalePriceCents,
              );
              const [newMarginPercent, newMarginDollars] = displayMargin(
                inputRow.newLandedCostCents,
                inputRow.newSalePriceCents,
              );
              inputRow.newMarginPercent = newMarginPercent;
              inputRow.newMarginDollars = newMarginDollars;
              inputRow.newMarginCents = dollarsToCents(newMarginDollars);
            }
            break;
          }
        }
      }
    },
    setWarehousePriceInputRowOnBlur: (
      state,
      action: PayloadAction<InputRowPayload>,
    ) => {
      const inputRow = state.warehousePriceInputRows?.find(
        row => row.warehouseID === action.payload.uuid,
      );
      if (inputRow) {
        const { inputType, value } = action.payload.eventReduced;
        switch (inputType) {
          case 'newLandedCost': {
            inputRow.newLandedCost = checkAndFormatDollarInput(value);
            // Update Custom Price table values if warehouse landed cost changes ~~~>
            const customPriceInputRows = state.customPriceInputRows.filter(
              row => row.warehouseID === action.payload.uuid,
            );
            for (const customPriceInputRow of customPriceInputRows) {
              customPriceInputRow.landedCostCents = inputRow.newLandedCostCents;
              const [newMarginPercent, newMarginDollars] = displayMargin(
                customPriceInputRow.landedCostCents,
                customPriceInputRow.newCustomPriceCents,
              );
              customPriceInputRow.currentMarginPercent = newMarginPercent;
              customPriceInputRow.currentMarginDollars = newMarginDollars;
            }
            // <~~~ Update Custom Price table values if warehouse landed cost changes
            break;
          }
          case 'newMarginPercent':
            inputRow.newMarginPercent = checkAndFormatPercentInput(value);
            break;
          case 'newMarginDollars':
            inputRow.newMarginDollars = checkAndFormatDollarInput(value);
            break;
          case 'newSalePrice': {
            // For sales price only, if value is empty string, then keep as empty string
            inputRow.newSalePrice =
              value.trim() && checkAndFormatDollarInput(value);
            /* If previous value exists and user tries to set to null value, then inform
             * user -> because value currently exists in db, cannot save as null */
            if (
              inputRow.currentSalePriceCents !== null &&
              inputRow.newSalePriceCents === null
            ) {
              /* Because sales price will be reset to previous value, we should consider this row
               * as unchanged if landed cost remains untouched to avoid saving unchanged values*/
              if (
                inputRow.currentLandedCostCents === inputRow.newLandedCostCents
              ) {
                inputRow.inputRowChanged = false;
              }
              // eslint-disable-next-line no-alert
              alert(
                'Sales Price must have value in order to be saved if value already exists in database.',
              );
              // Revert Sale Price to previous value
              inputRow.newSalePriceCents =
                inputRow.currentSalePriceCents as number;
              inputRow.newSalePrice = centsToDollars(
                inputRow.newSalePriceCents,
              );
              // Revert margin to previous values
              inputRow.newMarginPercentFloat = precisionMarginPercent(
                inputRow.newLandedCostCents,
                inputRow.newSalePriceCents,
              );
              const [newMarginPercent, newMarginDollars] = displayMargin(
                inputRow.newLandedCostCents,
                inputRow.newSalePriceCents,
              );
              inputRow.newMarginPercent = newMarginPercent;
              inputRow.newMarginDollars = newMarginDollars;
              inputRow.newMarginCents = dollarsToCents(newMarginDollars);
            }
            break;
          }
        }
      }
    },
    clearCustomPriceLocationSearchResults: state => {
      state.customPriceLocationSearchResults = [];
    },
    filterSelectedCustomPriceLocationSearchResults: state => {
      state.customPriceLocationSearchResults =
        state.customPriceLocationSearchResults.filter(
          locationRow => locationRow.selected,
        );
    },
    setCustomPriceLocationSearchResultSelected: (
      state,
      action: PayloadAction<{ locationID: string; selected: boolean }>,
    ) => {
      const locationRow = state.customPriceLocationSearchResults.find(
        locationRow => locationRow.locationID === action.payload.locationID,
      );
      if (locationRow) {
        locationRow.selected = action.payload.selected;
      }
    },
    // Adds new locations to custom pricing table from Add Location Modal on Continue
    addToCustomPriceInputRows: state => {
      const locationsToBeAdded = state.customPriceLocationSearchResults.reduce<
        CustomPriceInputRow[]
      >((locations, next) => {
        const locationAlreadyAdded = state.customPriceInputRows.some(
          prevAddedLocation => prevAddedLocation.locationID === next.locationID,
        );
        if (locationAlreadyAdded || !next.selected) return locations;
        // Access purchase price and landed cost from item state, `warehousePriceInputRows`
        const { purchasePriceCents, newLandedCostCents: landedCostCents } =
          state.warehousePriceInputRows.find(
            row => row.warehouseID === next.warehouseID,
          ) as WarehousePriceInputRow;
        const [currentMarginPercent, currentMarginDollars] = displayMargin(
          landedCostCents,
          null,
        );
        const newMarginPercentFloat = precisionMarginPercent(
          landedCostCents,
          null,
        );
        return locations.concat([
          {
            locationID: next.locationID,
            locationName: next.locationName,
            warehouseID: next.warehouseID,
            warehouseName: next.warehouseName,
            purchasePriceCents,
            landedCostCents,
            currentMarginPercent,
            currentMarginDollars,
            currentCustomPriceCents: null,
            currentCustomPriceDollars: '',
            currentCustomPriceCurrency: Currency.Usd,
            newMarginPercent: currentMarginPercent,
            newMarginDollars: currentMarginDollars,
            newMarginPercentFloat,
            newCustomPriceCents: null,
            newCustomPriceDollars: '',
            inputRowChanged: true,
            softDeleted: false,
            prevSaved: false,
          },
        ]);
      }, []);
      state.customPriceInputRows = [
        // Sort in alphabetical order by location name
        ...locationsToBeAdded.sort((left, right) => {
          return left && right
            ? left.locationName.localeCompare(right.locationName)
            : 0;
        }),
        ...state.customPriceInputRows,
      ];
    },
    setCustomPriceInputRowOnChange: (
      state,
      action: PayloadAction<InputRowPayload>,
    ) => {
      const inputRow = state.customPriceInputRows.find(
        row => row.locationID === action.payload.uuid,
      );
      if (inputRow) {
        const { inputType, value } = action.payload.eventReduced;
        switch (inputType) {
          case 'newMarginPercent': {
            const validated = validateMarginPercentInput(value);
            if (validated) {
              inputRow.inputRowChanged = true;
              inputRow.newMarginPercent = value;
              inputRow.newMarginPercentFloat =
                extractMarginPercentFromInput(value);
              // Calculate and update custom price values
              const [newCustomPriceDollars, newCustomPriceCents] =
                calculateSalePrice(
                  inputRow.landedCostCents ?? inputRow.purchasePriceCents,
                  inputRow.newMarginPercentFloat,
                );
              inputRow.newCustomPriceDollars = newCustomPriceDollars;
              inputRow.newCustomPriceCents = newCustomPriceCents;
              // Calculate and update margin dollars
              inputRow.newMarginDollars = displayMargin(
                inputRow.landedCostCents,
                inputRow.newCustomPriceCents,
              )[1];
            }
            break;
          }
          case 'newCustomPriceDollars': {
            const validated = validateDollarInput(value);
            if (validated) {
              inputRow.inputRowChanged = true;
              inputRow.newCustomPriceDollars = value;
              inputRow.newCustomPriceCents =
                (value.trim() && dollarsToCents(value)) || null;
              const [newMarginPercent, newMarginDollars] = displayMargin(
                inputRow.landedCostCents,
                inputRow.newCustomPriceCents,
              );
              inputRow.newMarginPercent = newMarginPercent;
              inputRow.newMarginDollars = newMarginDollars;
            }
            break;
          }
        }
      }
    },
    setCustomPriceInputRowOnBlur: (
      state,
      action: PayloadAction<InputRowPayload>,
    ) => {
      const inputRow = state.customPriceInputRows.find(
        row => row.locationID === action.payload.uuid,
      );
      if (inputRow) {
        const { inputType, value } = action.payload.eventReduced;
        switch (inputType) {
          case 'newMarginPercent':
            inputRow.newMarginPercent = checkAndFormatPercentInput(value);
            break;
          case 'newCustomPriceDollars':
            /* If previous value exists and user tries to set to null value, then inform
             * user -> because value currently exists in db, cannot save as null */
            if (inputRow.prevSaved && value === '') {
              // eslint-disable-next-line no-alert
              alert(
                'Custom Price must have value in order to be saved if value already exists in database.',
              );
              inputRow.newCustomPriceCents = inputRow.currentCustomPriceCents;
              inputRow.newCustomPriceDollars =
                inputRow.currentCustomPriceDollars;
              const [currentMarginPercent, currentMarginDollars] =
                displayMargin(
                  inputRow.landedCostCents,
                  inputRow.newCustomPriceCents,
                );
              inputRow.currentMarginPercent = currentMarginPercent;
              inputRow.currentCustomPriceDollars = currentMarginDollars;
              inputRow.inputRowChanged = false;
            } else {
              // if value is empty string, then keep as empty string
              inputRow.newCustomPriceDollars =
                value.trim() && checkAndFormatDollarInput(value);
            }
            break;
        }
      }
    },
    softDeleteLocationCustomPrice: (state, action: PayloadAction<string>) => {
      const inputRow = state.customPriceInputRows.find(
        row => row.locationID === action.payload,
      );
      if (inputRow) {
        inputRow.softDeleted = true;
        inputRow.inputRowChanged = true;
      }
    },
    restoreSoftDeletedLocationCustomPrices: state => {
      for (const row of state.customPriceInputRows) {
        row.softDeleted = false;
      }
    },
    // Resets Custom Pricing table values on discard
    resetCustomPriceInputRows: state => {
      state.customPriceInputRows = state.customPriceInputRows.reduce<
        CustomPriceInputRow[]
      >((customPrices, next) => {
        // Access landed cost from item state, `warehousePriceInputRows`
        const { currentLandedCostCents } = state.warehousePriceInputRows.find(
          row => row.warehouseID === next.warehouseID,
        ) as WarehousePriceInputRow;
        return !next.prevSaved
          ? customPrices
          : customPrices.concat([
              {
                locationID: next.locationID,
                locationName: next.locationName,
                warehouseID: next.warehouseID,
                warehouseName: next.warehouseName,
                purchasePriceCents: next.purchasePriceCents,
                // From item state, `warehousePriceInputRows`
                landedCostCents: currentLandedCostCents ?? null,
                currentMarginPercent: next.currentMarginPercent,
                currentMarginDollars: next.currentMarginDollars,
                currentCustomPriceCents: next.currentCustomPriceCents,
                currentCustomPriceDollars: next.currentCustomPriceDollars,
                currentCustomPriceCurrency: next.currentCustomPriceCurrency,
                newMarginPercent: next.currentMarginPercent,
                newMarginDollars: next.currentMarginDollars,
                newMarginPercentFloat: precisionMarginPercent(
                  next.landedCostCents,
                  next.currentCustomPriceCents,
                ),
                newCustomPriceCents: next.currentCustomPriceCents,
                newCustomPriceDollars: next.currentCustomPriceDollars,
                inputRowChanged: false,
                softDeleted: false,
                prevSaved: true,
                priceRuleId: next.priceRuleId,
              },
            ]);
      }, []);
    },
  },
  extraReducers: builder => {
    builder.addCase(queryItemById.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(queryItemById.fulfilled, (state, action) => {
      state.isLoading = false;
      // prettier-ignore
      const payload = JSON.parse(action.payload) as OperationResult<PricingGetItemForEditQuery, object>;
      const { error, data } = payload;
      if (data?.viewer?.availableItemById) {
        state.error = null;
        // Store all response data here for item query
        state.itemResponseData = data;
        const item = data.viewer?.availableItemById as
          | InventoryItem
          | NonInventoryItem;
        // Set input value rows for warehouse pricing table
        state.warehousePriceInputRows =
          shapeInitialWarehousePriceInputRows(item);
        // Set input value rows for custom pricing table
        state.customPriceInputRows = shapeInitialCustomPriceInputRows(
          (item.itemLocationsCustomPrice as ItemLocationCustomPrice[]) ?? [],
          state.warehousePriceInputRows,
        );
      } else if (error) {
        state.itemResponseData = null;
        state.error = error.message;
        void notify(
          error.message,
          `queryItemById: ${new Error().stack as string}`,
        );
      } else {
        /* As of current IMS implementation, no error is returned if
         * supplied UUID returns no item. If item is null and no error
         * is returned then the item probably does not exist. */
        state.itemResponseData = null;
        const errorMessage =
          'There was a problem fetching this item. Please double-check that item id is valid in URL.';
        state.error = errorMessage;
        // eslint-disable-next-line no-alert
        alert(errorMessage);
      }
    });
    builder.addCase(queryItemById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(mutateItemPrices.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(mutateItemPrices.fulfilled, (state, action) => {
      state.isSaving = false;
      // prettier-ignore
      const payload = JSON.parse(action.payload) as UseMutationState<PricingUpdateItemPricesMutation, object>;
      const { data, error } = payload;
      if (data?.updateItemPrices.result?.__typename === 'ItemPricesSuccess') {
        // Update Price Rule as necessary by creating map of locations under item
        const itemPriceRuleMap: { [key: string]: string } = {};
        const itemOdekoUuid =
          state.itemResponseData?.viewer?.availableItemById?.id;
        const itemLocationCustomPrices = data.updateItemPrices.result
          ?.itemLocationCustomPrices as ItemLocationCustomPrice[];

        itemLocationCustomPrices.forEach(i => {
          const itemId = i.itemId as string;
          const locationId = i.location?.id as string;
          const priceRuleId = i.priceRuleId as string;

          if (!itemId || !priceRuleId || !locationId) return;

          if (itemId === itemOdekoUuid) {
            itemPriceRuleMap[locationId] = priceRuleId;
          }
        });

        // Updates all read-only values on save
        for (const row of state.warehousePriceInputRows) {
          if (row.inputRowChanged === true && row.newSalePriceCents !== null) {
            row.currentLandedCostCents = row.newLandedCostCents;
            row.currentMarginPercent = row.newMarginPercent;
            row.currentMarginDollars = row.newMarginDollars;
            row.currentSalePriceCents = row.newSalePriceCents;
            row.inputRowChanged = false;
          }
        }
        state.customPriceInputRows = state.customPriceInputRows
          .reduce<CustomPriceInputRow[]>((locations, next) => {
            return next.softDeleted
              ? locations
              : locations.concat([
                  {
                    locationID: next.locationID,
                    locationName: next.locationName,
                    warehouseID: next.warehouseID,
                    warehouseName: next.warehouseName,
                    purchasePriceCents: next.purchasePriceCents,
                    landedCostCents: next.landedCostCents,
                    currentMarginPercent: next.newMarginPercent,
                    currentMarginDollars: next.newMarginDollars,
                    currentCustomPriceCents: next.newCustomPriceCents,
                    currentCustomPriceDollars: next.newCustomPriceDollars,
                    currentCustomPriceCurrency: next.currentCustomPriceCurrency,
                    newMarginPercent: next.newMarginPercent,
                    newMarginDollars: next.newMarginDollars,
                    newMarginPercentFloat: next.newMarginPercentFloat,
                    newCustomPriceCents: next.newCustomPriceCents,
                    newCustomPriceDollars: next.newCustomPriceDollars,
                    inputRowChanged: false,
                    softDeleted: false,
                    prevSaved: true,
                    priceRuleId:
                      itemPriceRuleMap[next.locationID] || next.priceRuleId,
                  },
                ]);
          }, [])
          .sort((left, right) => {
            return left && right
              ? left.locationName.localeCompare(right.locationName)
              : 0;
          });
      } else if (error) {
        state.error = action.payload;
        void notify(
          action.payload,
          `mutateItemPrices: ${new Error().stack as string}`,
        );
        // eslint-disable-next-line no-alert
        alert(`There was a problem saving your data. Error: ${error.message}`);
      } else {
        const errorMessage =
          data?.updateItemPrices.result?.__typename === 'ErrorsResult'
            ? data.updateItemPrices.result.errors.head.message
            : 'Unexpected response from server';
        state.error = errorMessage;
        void notify(
          errorMessage,
          `mutateItemPrices: ${new Error().stack as string}`,
        );
        // eslint-disable-next-line no-alert
        alert(`There was a problem saving your data. Error: ${errorMessage}`);
      }
    });
    builder.addCase(mutateItemPrices.rejected, (state, action) => {
      state.isSaving = false;
      state.error = action.payload as string;
    });
    builder.addCase(getLocationsFromSearch.pending, state => {
      state.isSearching = true;
    });
    builder.addCase(getLocationsFromSearch.fulfilled, (state, action) => {
      state.isSearching = false;
      // prettier-ignore
      const payload = JSON.parse(action.payload) as OperationResult<PricingGetLocationsFromSearchQuery, object>;
      const { error, data } = payload;
      if (error) {
        state.customPriceLocationSearchResults = [];
        state.error = error.message;
        void notify(
          error.message,
          `getLocationsFromSearch: ${new Error().stack as string}`,
        );
      } else if (data) {
        state.isSearching = false;
        state.error = null;
        const prevSelectedResults =
          state.customPriceLocationSearchResults.filter(
            locationRowSearchResult => locationRowSearchResult.selected,
          );
        const prevAddedLocations = state.customPriceInputRows;
        const locationNodes = data.viewer?.locations.nodes || [];
        /* Filter out null elements and filter out location if already exists
         * in prevSearchResults or in custom pricing table. We do not want to
         * surface duplicate locations in search results if location is selected. */
        const locations = locationNodes.reduce<LocationSearchResult[]>(
          (filteredLocations, location) => {
            return !location ||
              [...prevSelectedResults, ...prevAddedLocations].some(
                ({ locationID }) => locationID === location.id,
              )
              ? filteredLocations
              : filteredLocations.concat([
                  {
                    locationID: location.id,
                    locationName: location.businessName,
                    warehouseID: location.warehouse.id,
                    warehouseName: location.warehouse.name,
                    selected: false,
                  },
                ]);
          },
          [],
        );
        state.customPriceLocationSearchResults = [
          ...prevSelectedResults,
          ...locations,
        ];
      }
    });
    builder.addCase(getLocationsFromSearch.rejected, (state, action) => {
      state.isSearching = false;
      state.error = action.payload as string;
      void notify(
        action.payload,
        `getLocationsFromSearch: ${new Error().stack as string}`,
      );
    });
  },
});

export const {
  setWarehousePriceInputRows,
  resetWarehousePriceInputRows,
  setWarehousePriceInputRowOnChange,
  setWarehousePriceInputRowOnBlur,
  clearCustomPriceLocationSearchResults,
  filterSelectedCustomPriceLocationSearchResults,
  setCustomPriceLocationSearchResultSelected,
  addToCustomPriceInputRows,
  setCustomPriceInputRowOnChange,
  setCustomPriceInputRowOnBlur,
  softDeleteLocationCustomPrice,
  restoreSoftDeletedLocationCustomPrices,
  resetCustomPriceInputRows,
} = itemSlice.actions;

export default itemSlice.reducer;
