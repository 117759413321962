import { Box, Text } from 'theme-ui';

const ItemPricingHeader = () => {
  return (
    <>
      <Text sx={{ variant: 'text.headingMd' }}>Warehouse Pricing</Text>
      <Box sx={{ my: '2' }}>
        <Text
          sx={{ variant: 'text.paragraphMd', fontFamily: 'Roobert-Regular' }}>
          The Sales price created here will be reflected under Warehouse/Price
          Assocation on the Item Details page.
        </Text>
      </Box>
    </>
  );
};

export default ItemPricingHeader;
