import { useAppSelector } from '@/hooks/store';
import { Flex, Text, Box } from 'theme-ui';
import { selectEditMode } from '@/state/ui/selectors';
import { selectCustomPriceExists } from '@/state/item/selectors';
import AddLocationButton from './AddLocationButton';

const ItemCustomPricingHeader = () => {
  const customPriceExists = useAppSelector(selectCustomPriceExists);
  const editMode = useAppSelector(selectEditMode);

  return (
    <>
      <Flex sx={{ justifyContent: 'space-between', mt: '7' }}>
        <Text sx={{ variant: 'text.headingMd' }}>Custom Pricing</Text>
        {editMode ? <AddLocationButton /> : <Box />}
      </Flex>
      <Box sx={{ my: '2' }}>
        <Text
          sx={{ variant: 'text.paragraphMd', fontFamily: 'Roobert-Regular' }}>
          {customPriceExists
            ? `Custom Prices added here will be reflected in the customer’s portal
              when the item is added to their ordering grid.`
            : `No custom prices currently exist for this item. Click the Edit button at the top of the page to enter into edit mode and to add a location and create a custom price.`}
        </Text>
      </Box>
    </>
  );
};

export default ItemCustomPricingHeader;
