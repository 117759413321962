import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'theme-ui';
import { Auth0Provider } from '@auth0/auth0-react';
import { theme } from './theme';
import App from './App';
import store from './state/store';
import './assets/_fonts.css';
import { DoppioProvider } from '@odekoteam/doppio';
import { initErrorReporter } from './services/errorReporter';

initErrorReporter();

const domain = window.env.AUTH0_DOMAIN;
const clientId = window.env.AUTH0_CLIENT_ID;
const graphqlEndpoint = window.env.GRAPHQL_ENDPOINT;

render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    redirectUri={window.location.origin}
    audience={graphqlEndpoint}
    scope="read:current_user update:current_user_metadata">
    <React.StrictMode>
      <Provider store={store}>
        <DoppioProvider applicationName="pricing-web">
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </DoppioProvider>
      </Provider>
    </React.StrictMode>
  </Auth0Provider>,
  document.getElementById('root'),
);
