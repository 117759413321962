import { Box, Image, Text, ThemeUICSSObject } from 'theme-ui';
import ShareIcon from '@/assets/images/ShareIcon.svg';

const copyLinkStyle: ThemeUICSSObject = {
  display: 'inline-flex',
  alignItems: 'center',
  mr: '60px',
  cursor: 'pointer',
};

const secondaryHeaderTextStyle: ThemeUICSSObject = {
  color: 'secondaryText',
  variant: 'text.paragraphBoldSm',
  px: '8px',
};

const CopyLink = () => {
  const handleCopyClick = () => {
    const url = window.location.href;
    void navigator.clipboard.writeText(url);
  };

  return (
    <Box sx={copyLinkStyle} onClick={handleCopyClick}>
      <Image src={ShareIcon} />
      <Text sx={secondaryHeaderTextStyle}>Copy Link</Text>
    </Box>
  );
};

export default CopyLink;
