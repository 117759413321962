import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  PricingGetItemForEditQuery,
  PricingGetItemForEditQueryVariables,
  PricingGetLocationsFromSearchQueryVariables,
  PricingGetWarehousesQuery,
  PricingUpdateItemPricesMutation,
  PricingUpdateItemPricesMutationVariables,
  Scalars,
  UpdateItemPricesInput,
} from '@/generated/graphql-operations';
import { Warehouse } from './types';
import { client } from '@/graphql/client';
import {
  PRICING_GET_ITEM_FOR_EDIT,
  PRICING_GET_LOCATIONS_FROM_SEARCH,
  PRICING_GET_WAREHOUSES,
} from '@/graphql/queries';
import { PRICING_UPDATE_ITEM_PRICES } from '@/graphql/mutations';
import { setEditMode } from '../ui';
import { UseMutationState } from 'urql';

// https://redux-toolkit.js.org/api/createAsyncThunk
export const queryItemById = createAsyncThunk(
  'item/queryByIdStatus',
  async (id: Scalars['ID']) => {
    const { data: warehousesResult } = await client
      .query<PricingGetWarehousesQuery>(PRICING_GET_WAREHOUSES)
      .toPromise();
    const warehouses: Warehouse[] | undefined =
      warehousesResult?.warehouses?.map((warehouse: Warehouse) => {
        return { id: warehouse.id, name: warehouse.name };
      });
    const warehouseIds = warehouses?.map(w => w.id);

    const variables: PricingGetItemForEditQueryVariables = {
      itemId: id,
      warehouseIds,
    };

    const itemPricesResult = await client
      .query<PricingGetItemForEditQuery>(PRICING_GET_ITEM_FOR_EDIT, variables)
      .toPromise();
    return JSON.stringify(itemPricesResult);
  },
);

export const mutateItemPrices = createAsyncThunk(
  'item/mutateItemPricesStatus',
  async (priceInputs: UpdateItemPricesInput, thunkAPI) => {
    const variables: PricingUpdateItemPricesMutationVariables = {
      input: priceInputs,
    };
    const result = await client
      .mutation(PRICING_UPDATE_ITEM_PRICES, variables)
      .toPromise();
    const { data } = result as UseMutationState<
      PricingUpdateItemPricesMutation,
      object
    >;
    if (data) {
      /* Because this property belongs to uiSlice, we dispatch the state change here.
       * All itemSlice state changes happen in the itemSlice reducer */
      thunkAPI.dispatch(setEditMode(false));
    }
    return JSON.stringify(result);
  },
);

export const getLocationsFromSearch = createAsyncThunk(
  'item/getLocationsFromSearchStatus',
  async (searchString: string) => {
    const variables: PricingGetLocationsFromSearchQueryVariables = {
      first: 50,
      name: searchString,
    };
    const result = await client
      .query(PRICING_GET_LOCATIONS_FROM_SEARCH, variables)
      .toPromise();
    return JSON.stringify(result);
  },
);
