import { gql } from 'urql';

export const PRICING_UPDATE_ITEM_PRICES = gql`
  mutation pricingUpdateItemPrices($input: UpdateItemPricesInput!) {
    updateItemPrices(input: $input) {
      result {
        __typename
        ... on ItemPricesSuccess {
          updatedItemsCount
          updatedWarehouseItemsCount
          itemLocationCustomPrices {
            itemId
            location {
              id
            }
            customPrice {
              cents
              currency
            }
            priceRuleId
          }
        }
        ... on ErrorsResult {
          errors {
            head {
              message
            }
            tail {
              message
            }
          }
        }
      }
    }
  }
`;
