import { Flex, Image, Text, ThemeUICSSObject } from 'theme-ui';
import QuestionMark from '@/assets/images/QuestionMark.svg';

interface TableHeaderColumnProps {
  rowOneText: string | null;
  rowTwoText: string | null;
  questionIcon: boolean;
  thStyle: ThemeUICSSObject;
}

const headerColumnStyle: ThemeUICSSObject = {
  pt: '1',
};

const textStyle: ThemeUICSSObject = {
  fontFamily: 'Roobert-Bold',
  fontSize: '2',
};

const questionMarkStyle: ThemeUICSSObject = {
  px: '1',
  width: '22px',
};

const ItemPricingTableHeaderColumn = ({
  rowOneText,
  rowTwoText,
  questionIcon,
  thStyle,
}: TableHeaderColumnProps) => {
  return (
    <th sx={thStyle}>
      <Flex sx={headerColumnStyle}>
        <Text sx={textStyle}>{rowOneText}</Text>
        {questionIcon && <Image src={QuestionMark} sx={questionMarkStyle} />}
      </Flex>
      {questionIcon && (
        <Flex sx={headerColumnStyle}>
          <Text sx={textStyle}>{rowTwoText}</Text>
        </Flex>
      )}
    </th>
  );
};

export default ItemPricingTableHeaderColumn;
