import { useAppSelector } from '@/hooks/store';
import { selectShowModal } from '@/state/ui/selectors';
import { createPortal } from 'react-dom';
import CustomPricingAddLocationModal from './components/CustomPricingAddLocationModal';
import UnsavedChangesModal from './components/UnsavedChangesModal';

const ModalRenderer = () => {
  const showModal = useAppSelector(selectShowModal);

  return createPortal(
    <>
      {(showModal === 'UNSAVED_CHANGES_DISCARD' ||
        showModal === 'UNSAVED_CHANGES_CLOSE') && <UnsavedChangesModal />}
      {showModal === 'CUSTOM_PRICING_ADD_LOCATION' && (
        <CustomPricingAddLocationModal />
      )}
    </>,
    document.body,
  );
};

export default ModalRenderer;
