import { useAppSelector } from '@/hooks/store';
import { selectLocationSearchResults } from '@/state/item/selectors';
import { ThemeUICSSObject, Text } from 'theme-ui';
import ModalTableDataRow from './ModalTableDataRow';

const tableStyle: ThemeUICSSObject = {
  width: '100%',
  height: '72%',
  borderCollapse: 'collapse',
  tableLayout: 'fixed',
};

const tableHeaderStyle: ThemeUICSSObject = {
  display: 'block',
  height: '10%',
  bg: 'backgroundWeak',
  textAlign: 'left',
  pt: '1',
};

const tableHeaderTextStyle: ThemeUICSSObject = {
  fontFamily: 'Roobert-Bold',
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '24px',
  color: '#615E54',
};

const tableBodyStyle: ThemeUICSSObject = {
  display: 'block',
  height: '90%',
  overflowY: 'scroll',
};

const ModalTable = () => {
  const locationSearchResults = useAppSelector(selectLocationSearchResults);

  return (
    <table sx={tableStyle}>
      <thead sx={tableHeaderStyle}>
        <tr>
          <th sx={{ width: '12.5%' }}></th>
          <th sx={{ width: '61%' }}>
            <Text sx={tableHeaderTextStyle}>Business Name</Text>
          </th>
          <th>
            <Text sx={tableHeaderTextStyle}>Odeko Location UUID</Text>
          </th>
        </tr>
      </thead>
      <tbody sx={tableBodyStyle}>
        {locationSearchResults.map(({ locationID, locationName }, index) => (
          <ModalTableDataRow
            key={index}
            locationID={locationID}
            locationName={locationName}
          />
        ))}
      </tbody>
    </table>
  );
};

export default ModalTable;
