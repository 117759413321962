import Header, { HEADER_HEIGHT } from './Header';
import ItemDetails from './ItemDetails';
import ItemPricing from './ItemPricing';
import { Box, Flex, ThemeUICSSObject } from 'theme-ui';
import { Scalars } from '@/generated/graphql-operations';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '@/hooks/store';
import { queryItemById } from '@/state/item/operations';
import { useEffect, useRef, useState } from 'react';
import RenderModal from './modal/ModalRenderer';
import ItemCustomPricing from './ItemCustomPricing';
import { TOP_OFFSET } from './DevelopmentEnvironmentBanner';
import NavigationSidebar, { SIDEBAR_WIDTH } from './NavigationSidebar';

const bodyContainerStyle: ThemeUICSSObject = {
  mt: `${TOP_OFFSET + HEADER_HEIGHT}px`,
  width: '100%',
  pb: '500px',
};

const contentContainerStyle: ThemeUICSSObject = {
  pl: '7',
};

const topBoxStyle: ThemeUICSSObject = {
  width: '85%',
  pb: '5',
};

const bottomBoxStyle: ThemeUICSSObject = {
  width: '93%',
};

const PricingController = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: Scalars['ID'] }>();
  useEffect(() => {
    void dispatch(queryItemById(id));
  }, [dispatch, id]);

  // RESPONSIVE LAYOUT CONTROL~~~~~~~~>
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  const breakpoint = 800;
  const showSidebar = windowWidth > breakpoint;
  const contentContainerDynamicStyle: ThemeUICSSObject = {
    width: showSidebar ? `${100 - SIDEBAR_WIDTH}%` : '100%',
    ml: showSidebar ? `${SIDEBAR_WIDTH}%` : '0%',
  };
  // <~~~~~~~~RESPONSIVE LAYOUT CONTROL

  const salesPricingRef = useRef<HTMLDivElement>(null);
  const primaryInfoRef = useRef<HTMLDivElement>(null);
  const warehousePriceRef = useRef<HTMLDivElement>(null);
  const customPriceRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <Header />
      <Flex sx={bodyContainerStyle}>
        <NavigationSidebar
          showSidebar={showSidebar}
          salesPricingRef={salesPricingRef}
          primaryInfoRef={primaryInfoRef}
          warehousePriceRef={warehousePriceRef}
          customPriceRef={customPriceRef}
        />
        <Box sx={{ ...contentContainerStyle, ...contentContainerDynamicStyle }}>
          <Box sx={topBoxStyle}>
            <ItemDetails
              salesPricingRef={salesPricingRef}
              primaryInfoRef={primaryInfoRef}
            />
            <ItemPricing warehousePriceRef={warehousePriceRef} />
          </Box>
          <Box sx={bottomBoxStyle}>
            <ItemCustomPricing customPriceRef={customPriceRef} />
          </Box>
        </Box>
      </Flex>
      <RenderModal />
    </>
  );
};

export default PricingController;
