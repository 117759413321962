import { Grid, Box, Text, Paragraph } from 'theme-ui';
import ItemInfoRow from '@/components/ItemInfoRow';
import { formattedPrice } from '@/utils/readonly';
import { priceOutput } from '@/utils/PricingIO';
import { useAppSelector } from '@/hooks/store';
import { selectItemData } from '@/state/item/selectors';
import { RefObject } from 'react';

interface PrimaryInformationProps {
  primaryInfoRef: RefObject<HTMLDivElement>;
}

const PrimaryInformation = ({ primaryInfoRef }: PrimaryInformationProps) => {
  const item = useAppSelector(selectItemData);

  const isInventoryItem = item?.__typename === 'InventoryItem';

  const data = {
    typename: item?.__typename,
    name: item?.name,
    vendorCode: item?.vendorCode,
    upcCodes: isInventoryItem ? item?.upcCodes : null,
    purchasePrice: item?.purchasePrice
      ? formattedPrice(priceOutput(item.purchasePrice))
      : null,
    stockUnit: isInventoryItem ? item?.stockUnit : null,
    purchaseUnit: isInventoryItem ? item?.purchaseUnit : item?.saleUnit,
    saleUnit: item?.saleUnit,
    brandName: item?.brandName,
    itemUuid: item?.id,
  };

  const itemType =
    data.typename === 'InventoryItem' ? 'Inventory Item' : 'NonInventory Item';

  return (
    <Box sx={{ marginY: 6 }} ref={primaryInfoRef}>
      <Text sx={{ variant: 'text.labelLg' }}>Primary Information</Text>
      <Box
        sx={{
          borderBottom: '1px solid',
          borderBottomColor: 'secondaryBackgroundDisabled',
          marginY: 3,
        }}
      />
      <Paragraph sx={{ variant: 'text.labelMd', marginBottom: 2 }}>
        {itemType}
      </Paragraph>
      <Grid columns={2} sx={{ mt: 0 }}>
        <Box>
          <ItemInfoRow propertyName="Name" value={data.name} />
          <ItemInfoRow propertyName="Brand" value={data.brandName} />

          <ItemInfoRow propertyName="Vendor Code" value={data.vendorCode} />
          <ItemInfoRow propertyName="UUID" value={data.itemUuid} />
        </Box>
        <Box>
          <ItemInfoRow
            propertyName="Purchase Price"
            value={data.purchasePrice}
          />
          {isInventoryItem && (
            <ItemInfoRow
              propertyName="Purchase Unit"
              value={data.purchaseUnit?.name}
            />
          )}
          {data.stockUnit && (
            <ItemInfoRow
              propertyName="Stock Unit"
              value={data.stockUnit.name}
            />
          )}
          <ItemInfoRow propertyName="Sales Unit" value={data.saleUnit?.name} />
        </Box>
      </Grid>
    </Box>
  );
};

export default PrimaryInformation;
