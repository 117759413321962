import { Box } from 'theme-ui';
import { useAppSelector } from '@/hooks/store';
import ItemCustomPricingHeader from './ItemCustomPricingHeader';
import ItemCustomPricingTable from './ItemCustomPricingTable';
import { selectCustomPriceExists } from '@/state/item/selectors';
import { RefObject } from 'react';

interface ItemCustomPricingProps {
  customPriceRef: RefObject<HTMLDivElement>;
}

const ItemCustomPricing = ({ customPriceRef }: ItemCustomPricingProps) => {
  const customPriceExists = useAppSelector(selectCustomPriceExists);

  return (
    <Box ref={customPriceRef}>
      <ItemCustomPricingHeader />
      {customPriceExists ? <ItemCustomPricingTable /> : <Box />}
    </Box>
  );
};

export default ItemCustomPricing;
