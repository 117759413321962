import { gql } from 'urql';

export const PRICING_GET_WAREHOUSES = gql`
  query pricingGetWarehouses {
    warehouses {
      id
      name
    }
  }
`;

export const PRICING_GET_ITEM_FOR_EDIT = gql`
  query pricingGetItemForEdit($itemId: ID!, $warehouseIds: [ID!]) {
    viewer {
      availableItemById(id: $itemId) {
        __typename
        id
        name
        brandName
        description
        catalogStatus
        imgUrl
        createdAt
        updatedAt
        vendorCode
        category {
          name
        }
        subcategory {
          name
        }
        ... on InventoryItem {
          __typename
          perishability
          upcCodes
          shelfLifeDays
          locationShelfLifeDays
          stockUnit {
            name
          }
          purchaseUnit {
            name
            conversionRate
          }
          warehouseAssociation(warehouseIds: $warehouseIds) {
            warehouse {
              id
              name
            }
            landedCost {
              cents
              currency
            }
            salePrice {
              cents
              currency
            }
          }
          erpRecordUrl
          itemLocationsCustomPrice {
            location {
              id
              businessName
              warehouse {
                id
                name
              }
            }
            customPrice {
              cents
              currency
            }
            priceRuleId
          }
        }
        ... on NonInventoryItem {
          __typename
          minItemsPerOrder
          purchaseMultiple
          warehouseAssociation(warehouseIds: $warehouseIds) {
            warehouse {
              id
              name
            }
            landedCost {
              cents
              currency
            }
            salePrice {
              cents
              currency
            }
          }
          erpRecordUrl
          itemLocationsCustomPrice {
            location {
              id
              businessName
              warehouse {
                id
                name
              }
            }
            customPrice {
              cents
              currency
            }
            priceRuleId
          }
        }
        purchasePrice {
          cents
          currency
        }
        saleUnit {
          name
          unitType
          conversionRate
        }
      }
    }
  }
`;

export const PRICING_GET_LOCATIONS_FROM_SEARCH = gql`
  query pricingGetLocationsFromSearch($first: Int!, $name: String!) {
    viewer {
      locations(first: $first, name: $name) {
        nodes {
          id
          businessName
          warehouse {
            id
            name
          }
        }
      }
    }
  }
`;
