import { Flex, Box, Button, ThemeUICSSObject } from 'theme-ui';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { setEditMode, setShowModal } from '@/state/ui';
import { shapeDataPriceMutation } from '@/utils/PricingIO';
import { mutateItemPrices } from '@/state/item/operations';
import { restoreSoftDeletedLocationCustomPrices } from '@/state/item';
import {
  selectCustomPriceInputRows,
  selectInvalidNullCustomPriceExists,
  selectLocationNamesOfCustomPricesToBeDeleted,
  selectUnsavedChangeExists,
  selectWarehousePriceInputRows,
} from '@/state/item/selectors';

const flexContainerStyle: ThemeUICSSObject = {
  py: '2',
  mr: '11',
  flexDirection: 'row-reverse',
};

const ItemPricingEditModeButtons = () => {
  const dispatch = useAppDispatch();
  // State data and conditional values ~~~~~~>
  const warehousePriceInputRows = useAppSelector(selectWarehousePriceInputRows);
  const customPriceInputRows = useAppSelector(selectCustomPriceInputRows);
  const unsavedChangeExists = useAppSelector(selectUnsavedChangeExists);
  const invalidNullCustomPrice = useAppSelector(
    selectInvalidNullCustomPriceExists,
  );
  const intentToDelete = customPriceInputRows.some(
    inputRow => inputRow.prevSaved && inputRow.softDeleted,
  );
  const locationNamesToDelete = useAppSelector(
    selectLocationNamesOfCustomPricesToBeDeleted,
  );
  // <~~~~~~ State data and conditional values

  const savePrices = () => {
    const payload = shapeDataPriceMutation(
      warehousePriceInputRows,
      customPriceInputRows,
    );
    void dispatch(mutateItemPrices(payload));
  };
  const confirmDeleteBeforeSave = () => {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        `Are you sure you want to delete prices for the following locations: ${locationNamesToDelete}?`,
      )
    ) {
      savePrices();
    } else {
      dispatch(restoreSoftDeletedLocationCustomPrices());
    }
  };

  // Click Event Handlers ~~~~~~>
  const handleDiscard = () => {
    if (unsavedChangeExists) {
      dispatch(setShowModal('UNSAVED_CHANGES_DISCARD'));
    } else {
      dispatch(setEditMode(false));
    }
  };
  const handleSubmit = () => {
    if (invalidNullCustomPrice) {
      // eslint-disable-next-line no-alert
      alert('Custom price values cannot be blank.');
    } else if (intentToDelete) {
      confirmDeleteBeforeSave();
    } else {
      savePrices();
    }
  };
  // <~~~~~~ Click Event Handlers

  return (
    <Flex sx={flexContainerStyle}>
      <Box sx={{ p: '1' }}>
        <Button onClick={handleSubmit} variant="cta">
          Save
        </Button>
      </Box>
      <Box sx={{ p: '1' }}>
        <Button onClick={handleDiscard} variant="btn_outline">
          Discard
        </Button>
      </Box>
    </Flex>
  );
};

export default ItemPricingEditModeButtons;
