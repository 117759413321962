import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ModalType, UiState } from './types';

const initialState: UiState = {
  editMode: false,
  showModal: null,
  // Out of scope for v1, but included here to give an idea of what else the UI state will be used for.
  showPriceHistory: false,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setEditMode: (state, action: PayloadAction<boolean>) => {
      state.editMode = action.payload;
    },
    setShowModal: (state, action: PayloadAction<ModalType>) => {
      state.showModal = action.payload;
    },
  },
});

export const { setEditMode, setShowModal } = uiSlice.actions;

export default uiSlice.reducer;
