import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from './types';
import { User } from '@auth0/auth0-react';

const initialState: AuthState = {
  isAdmin: false,
  isAuthenticated: false,
  isLoading: false,
  token: null,
  user: {},
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, { payload }: PayloadAction<string>) => {
      state.token = payload;
      // TODO: Remove token from local storage
      localStorage.setItem('authToken', payload);
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setUser: (state, { payload }: PayloadAction<User>) => {
      state.user = payload;
      if (payload) {
        const roles: string[] =
          (payload['https://odeko.com/roles'] as string[]) || [];
        state.isAdmin = roles.includes('admin');
        state.isAuthenticated = true;
      } else {
        state.isAdmin = false;
        state.isAuthenticated = false;
      }
    },
  },
});

export const { setToken, setLoading, setUser } = authSlice.actions;

export default authSlice.reducer;
