import { Grid, Paragraph, ThemeUICSSObject } from 'theme-ui';

type ItemInfoRowParams = {
  propertyName: string;
  value?: any;
  children?: any;
  sx?: ThemeUICSSObject;
  className?: string;
};

const ItemInfoRow = ({
  propertyName,
  value,
  children,
  sx,
  className,
}: ItemInfoRowParams) => {
  return (
    <Grid columns={[4, '1fr 3fr']} sx={{ marginBottom: 2 }}>
      <Paragraph sx={{ variant: 'text.paragraphMd', color: 'secondaryLabel' }}>
        {propertyName}
      </Paragraph>
      <Paragraph
        className={className}
        sx={{ variant: 'text.paragraphMd', color: 'neutralDark', ...sx }}>
        {value}
        {children}
      </Paragraph>
    </Grid>
  );
};

export default ItemInfoRow;
