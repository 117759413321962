import Modal from '../ModalStyleWrapper';
import { Box, Flex, Heading, Paragraph, ThemeUICSSObject } from 'theme-ui';
import { Button } from '@odekoteam/doppio';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useParams } from 'react-router-dom';
import { Scalars } from '@/generated/graphql-operations';
import { setEditMode, setShowModal } from '@/state/ui';
import {
  resetWarehousePriceInputRows,
  resetCustomPriceInputRows,
} from '@/state/item';
import { navigateToItemManagement } from '@/utils/navigation';
import { selectShowModal } from '@/state/ui/selectors';

const modalStyle: ThemeUICSSObject = {
  padding: '32px',
  borderRadius: '3',
  maxWidth: '420px',
  height: 'auto',
};

const headerCSS: ThemeUICSSObject = {
  marginTop: '0',
  fontSize: '4',
  fontWeight: 'bold',
  marginBottom: '6',
};

const UnsavedChangesModal = () => {
  const showModalType = useAppSelector(selectShowModal);
  const dispatch = useAppDispatch();

  const { id } = useParams<{ id: Scalars['ID'] }>();
  const handleLeftButtonPress = () => {
    if (showModalType === 'UNSAVED_CHANGES_CLOSE') {
      navigateToItemManagement(id);
    } else if (showModalType === 'UNSAVED_CHANGES_DISCARD') {
      dispatch(setShowModal(null));
    }
  };

  const handleRightButtonPress = () => {
    if (showModalType === 'UNSAVED_CHANGES_DISCARD') {
      dispatch(resetWarehousePriceInputRows());
      dispatch(resetCustomPriceInputRows());
      dispatch(setEditMode(false));
    }
    dispatch(setShowModal(null));
  };

  let headingText, leftButtonText, rightButtonText;
  switch (showModalType) {
    case 'UNSAVED_CHANGES_CLOSE':
      headingText = 'Unsaved changes';
      leftButtonText = 'Leave and clear';
      rightButtonText = 'Stay and review';
      break;
    case 'UNSAVED_CHANGES_DISCARD':
      headingText = 'Discard all unsaved changes';
      leftButtonText = 'Cancel';
      rightButtonText = 'Discard changes';
      break;
  }

  return (
    <Modal modalStyle={modalStyle}>
      <Heading as="h3" sx={headerCSS}>
        {headingText}
      </Heading>
      <Paragraph sx={{ fontWeight: 'semibold' }}>
        You have unsaved changes. Are you sure you want to leave without saving
        them?
      </Paragraph>
      <Box sx={{ paddingTop: '9' }}>
        <Flex sx={{ flexDirection: 'row-reverse' }}>
          <Button onPress={handleRightButtonPress}>{rightButtonText}</Button>
          <Box sx={{ width: '2' }} />
          <Button variant="ghost" onPress={handleLeftButtonPress}>
            {leftButtonText}
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
};

export default UnsavedChangesModal;
