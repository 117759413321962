import { configureStore } from '@reduxjs/toolkit';
import AuthReducer from './auth/index';
import UiReducer from './ui';
import ItemReducer from './item';

const store = configureStore({
  reducer: {
    auth: AuthReducer,
    ui: UiReducer,
    item: ItemReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
