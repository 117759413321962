import type { Theme } from 'theme-ui';

// From https://theme-ui.com/guides/typescript/
const makeTheme = <T extends Theme>(t: T) => t;

export const theme = makeTheme({
  fonts: {
    body: 'GreycliffCF',
    heading: 'ResidenzGrotesk-Bold',
    monospace: 'SF-Regular, monospace',
    Roobert: 'Roobert-Regular',
  },
  fontSizes: [12, 14, 16, 20, 24, 32],
  fontWeights: {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  lineHeights: ['16px', '20px', '24px', '32px', '40px'],
  space: [
    0, 4, 8, 12, 16, 20, 24, 32, 40, 48, 56, 64, 72, 80, 96, 112, 128, 144,
  ],
  radii: [0, 4, 8, 16, 32, 40, 48, 56, 64],
  sizes: [0, 4, 8, 16, 32, 40, 48, 56, 64],
  colors: {
    // Neutral
    text: '#050400',
    background: '#FFFFFF',
    backgroundMediumBorder: '#EEEBE6',
    backgroundWeak: '#F4F3EF',
    backgroundWeakest: '#F9F9F7',
    // Primary
    primaryBackgroundHover: '#E0A100',
    primaryBackground: '#FFD100',
    primaryBorder: '#FFD100',
    primaryBackgroundActive: '#FBE8A8',
    primaryBackgroundWeak: '#FFF5D6',
    // Secondary
    secondaryBackgroundHover: '#291007',
    secondaryBackground: '#40180B',
    secondaryBorder: '#40180B',
    secondaryText: '#40180B',
    secondaryBackgroundActive: '#642511',
    secondaryBackgroundDisabled: '#E6E2DA',
    secondarySidebarText: '#C0BAAE',
    // Tertiary
    tertiaryBackground: '#FFFFDF',
    tertiaryBorder: '#FFFFDF',
    tertiaryText: '#FFFFDF',
    // Warning
    warningBackgroundHover: '#AE4B00',
    warningBackground: '#FF760F',
    warningBorder: '#FF760F',
    warningBackgroundActive: '#FFCFAB',
    warningBackgroundWeak: '#FFECDE',
    // Success
    successBackgroundHover: '#617103',
    successBackground: '#ACC806',
    successBorder: '#ACC806',
    successBackgroundActive: '#E2ECA8',
    successBackgroundWeak: '#F4F7DF',
    // Info
    infoBackgroundHover: '#1868C7',
    infoBackground: '#589FF1',
    infoBorder: '#589FF1',
    infoBackgroundActive: '#B2DBFF',
    infoBackgroundWeak: '#E5F4FF',
    // Error
    errorBackgroundHover: '#8B1407',
    errorBackground: '#F4230B',
    errorBorder: '#F4230B',
    errorBackgroundActive: '#FFD6D1',
    errorBackgroundWeak: '#FFF3F2',
  },
  text: {
    headingXs: {
      fontSize: 2,
      fontWeight: 'bold',
      lineHeight: 2,
      fontFamily: 'ResidenzGrotesk-Bold',
    },
    headingSm: {
      fontSize: 3,
      fontWeight: 'bold',
      lineHeight: 2,
      fontFamily: 'ResidenzGrotesk-Bold',
    },
    headingMd: {
      fontSize: 4,
      fontWeight: 'bold',
      lineHeight: 3,
      fontFamily: 'ResidenzGrotesk-Bold',
    },
    headingLg: {
      fontSize: 5,
      fontWeight: 'bold',
      lineHeight: 4,
      fontFamily: 'ResidenzGrotesk-Bold',
    },
    paragraphSm: {
      fontSize: 0,
      lineHeight: 1,
    },
    paragraphMd: {
      fontSize: 1,
      lineHeight: 1,
    },
    paragraphLg: {
      fontSize: 2,
      lineHeight: 2,
    },
    paragraphXl: {
      fontSize: 3,
      lineHeight: 2,
    },
    paragraphBoldSm: {
      variant: 'paragraphSm',
      fontWeight: 'bold',
    },
    paragraphBoldMd: {
      variant: 'paragraphMd',
      fontWeight: 'bold',
    },
    paragraphBoldLg: {
      variant: 'paragraphLg',
      fontWeight: 'bold',
    },
    paragraphBoldXl: {
      variant: 'paragraphXl',
      fontWeight: 'bold',
    },
    labelSm: {
      fontSize: 0,
      lineHeight: 0,
      fontWeight: 'semibold',
    },
    labelMd: {
      fontSize: 1,
      lineHeight: 1,
      fontWeight: 'semibold',
    },
    labelLg: {
      fontSize: 2,
      lineHeight: 1,
      fontWeight: 'semibold',
    },
    labelCapsSm: {
      textTransform: 'uppercase',
      fontSize: 0,
      lineHeight: 0,
      fontWeight: 'semibold',
    },
    labelCapsLg: {
      textTransform: 'uppercase',
      fontSize: 1,
      lineHeight: 1,
      fontWeight: 'semibold',
    },
    sidebarLink: {
      fontFamily: 'Roobert',
      fontSize: 2,
      lineHeight: '24px',
      cursor: 'pointer',
    },
    sidebarLinkPrimary: {
      variant: 'sidebarLink',
      fontFamily: 'Roobert-Bold',
      lineHeight: '24px',
      cursor: 'pointer',
    },
  },
  styles: {
    // https://theme-ui.com/theme-spec/#styles
    root: {
      fontFamily: 'body',
      fontSize: 2,
      fontWeight: 'regular',
      height: '100%',
      width: '100%',
      backgroundColor: '#FFFFFF',
    },
    // NOTE: More global styles in App.tsx. ThemeUI only exposes root for the
    // html tag, but nothing for the body or elements outside of the app which
    // are the target elements
    a: {
      textDecoration: 'none',
      color: 'text',
    },
  },
  buttons: {
    primary: {
      fontFamily: 'Roobert-Medium',
      bg: '#40180B',
      color: 'tertiaryText',
      p: 3,
      border: '2px solid',
      borderColor: 'neutralLight',
      borderRadius: 0,
      cursor: 'pointer',
      height: 5,
      fontSize: 2,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    btn_outline: {
      variant: 'buttons.primary',
      bg: 'background',
      color: 'text',
      border: '2px solid',
      borderColor: 'backgroundMediumBorder',
      paddingX: 7,
      borderRadius: 0,
      '&:hover': {
        bg: '#2F2A23',
        borderColor: '#2F2A23',
        color: 'tertiaryText',
      },
      '&:active': {
        bg: 'secondaryBackgroundActive',
        borderColor: 'secondaryBackgroundActive',
        color: 'tertiaryText',
      },
    },
    cta: {
      variant: 'buttons.primary',
      bg: '#FFD100',
      borderColor: '#FFD100',
      color: 'secondaryText',
      borderRadius: 0,
      paddingX: 8,
      '&:hover': {
        bg: 'primaryBackgroundHover',
        borderColor: 'primaryBackgroundHover',
      },
      '&:active': {
        bg: 'primaryBackgroundActive',
        borderColor: 'primaryBackgroundActive',
      },
    },
    btn_icon: {
      variant: 'buttons.primary',
      paddingLeft: 4,
      paddingRight: 3,
      paddingTop: 2,
      paddingBottom: 2,
      '&:hover': {
        bg: '#2F2A23',
      },
      '&:active': {
        bg: 'secondaryBackgroundActive',
      },
    },
    btn_icon_lt: {
      variant: 'buttons.primary',
      bg: 'background',
      color: 'secondaryText',
      border: '2px solid',
      borderColor: 'backgroundMediumBorder',
      paddingX: '4',
      '&:hover': {
        borderColor: 'primaryBackgroundActive',
      },
      '&:active': {
        borderColor: 'secondaryText',
      },
    },
    cta_sm: {
      variant: 'buttons.btn_icon',
      height: '36px',
      bg: '#FFD100',
      borderColor: '#FFD100',
      color: 'secondaryText',
      '&:hover': {
        bg: 'primaryBackgroundHover',
        borderColor: 'primaryBackgroundHover',
      },
      '&:active': {
        bg: 'primaryBackgroundActive',
        borderColor: 'primaryBackgroundActive',
      },
    },
    btn_disabled: {
      variant: 'buttons.primary',
      marginTop: '4',
      backgroundColor: 'secondaryDisabled',
      fontWeight: 'bold',
      color: 'secondaryLabel',
    },
    btn_clear: {
      variant: 'buttons.primary',
      bg: 'neutralLight',
      color: 'secondaryDefault',
      border: '2px solid',
      borderRadius: 0,
    },
  },
});

export type TypedTheme = typeof theme;
