import { Box, Close, Flex, Image, Text, ThemeUICSSObject } from 'theme-ui';
import VerticalDivider from '@/assets/images/VerticalDivider.svg';
import { useParams } from 'react-router-dom';
import { Scalars } from '@/generated/graphql-operations';
import { TOP_OFFSET } from './DevelopmentEnvironmentBanner';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { setShowModal } from '@/state/ui';
import { navigateToItemManagement } from '@/utils/navigation';
import ItemPricingEditModeButtons from './ItemPricingEditModeButtons';
import CopyLink from './CopyLink';
import { selectEditMode } from '@/state/ui/selectors';
import { selectUnsavedChangeExists } from '@/state/item/selectors';

export const HEADER_HEIGHT = 70;
const headerStyle: ThemeUICSSObject = {
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: 'thin solid #E6E2DA',
  bg: 'background',
  height: `${HEADER_HEIGHT}px`,
  pt: '3',
  top: `${TOP_OFFSET}px`,
  position: 'fixed',
  width: '100%',
};

const primaryHeaderTextStyle: ThemeUICSSObject = {
  variant: 'text.headingSm',
  px: '8px',
};

const Header = () => {
  const editMode = useAppSelector(selectEditMode);
  const unsavedChangeExists = useAppSelector(selectUnsavedChangeExists);
  const dispatch = useAppDispatch();

  const { id } = useParams<{ id: Scalars['ID'] }>();
  const handleClose = () => {
    if (editMode && unsavedChangeExists) {
      dispatch(setShowModal('UNSAVED_CHANGES_CLOSE'));
    } else {
      navigateToItemManagement(id);
    }
  };

  const primaryHeaderTextColor: ThemeUICSSObject = {
    color: editMode ? '#40180B' : 'text',
  };

  return (
    <Flex as="header" sx={headerStyle}>
      <Box sx={{ display: 'inline-flex', alignItems: 'center', pl: '60px' }}>
        <Close sx={{ cursor: 'pointer' }} onClick={handleClose} />
        <Image src={VerticalDivider} sx={{ px: '8px' }} />
        <Text sx={{ ...primaryHeaderTextStyle, ...primaryHeaderTextColor }}>
          {editMode ? 'Edit Pricing Page' : 'Manage Pricing'}
        </Text>
      </Box>
      {editMode ? <ItemPricingEditModeButtons /> : <CopyLink />}
    </Flex>
  );
};

export default Header;
