import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { TableRefOptions } from './types';
import { notify } from '@/services/errorReporter';

export const selectItemState = (state: RootState) => state.item;

export const selectItemData = createSelector(
  selectItemState,
  itemState => itemState.itemResponseData?.viewer?.availableItemById,
);

export const selectWarehousePriceInputRows = createSelector(
  selectItemState,
  itemState => itemState.warehousePriceInputRows,
);

export const selectCustomPriceInputRows = createSelector(
  selectItemState,
  itemState => itemState.customPriceInputRows,
);

// ID refers to either a warehouse or location ID depending on the table
export const selectNextRowID = (
  currentRowID: string,
  tableRef: TableRefOptions,
) => {
  switch (tableRef) {
    case TableRefOptions.WAREHOUSE_PRICES: {
      return createSelector(selectWarehousePriceInputRows, inputRows => {
        const currentInputRowIndex = inputRows.findIndex(
          inputRow => inputRow.warehouseID === currentRowID,
        );
        if (currentInputRowIndex === -1) {
          void notify(
            `Could not find input row for warehouse id: ${currentRowID}`,
          );
        }
        const lastInputRowIndex = inputRows.length - 1;
        return currentInputRowIndex !== lastInputRowIndex
          ? inputRows[currentInputRowIndex + 1].warehouseID
          : inputRows[0].warehouseID;
      });
    }
    case TableRefOptions.CUSTOM_PRICES: {
      return createSelector(selectCustomPriceInputRows, inputRows => {
        const currentInputRowIndex = inputRows.findIndex(
          inputRow => inputRow.locationID === currentRowID,
        );
        if (currentInputRowIndex === -1) {
          void notify(
            `Could not find input row for warehouse id: ${currentRowID}`,
          );
        }
        const lastInputRowIndex = inputRows.length - 1;
        return currentInputRowIndex !== lastInputRowIndex
          ? inputRows[currentInputRowIndex + 1].locationID
          : inputRows[0].locationID;
      });
    }
  }
};

// ID refers to either a warehouse or location ID depending on the table
export const selectPrevRowID = (
  currentRowID: string,
  tableRef: TableRefOptions,
) => {
  switch (tableRef) {
    case TableRefOptions.WAREHOUSE_PRICES: {
      return createSelector(selectWarehousePriceInputRows, inputRows => {
        const currentInputRowIndex = inputRows.findIndex(
          inputRow => inputRow.warehouseID === currentRowID,
        );
        if (currentInputRowIndex === -1) {
          void notify(
            `Could not find input row for warehouse id: ${currentRowID}`,
          );
        }
        const lastInputRowIndex = inputRows.length - 1;
        return currentInputRowIndex > 0
          ? inputRows[currentInputRowIndex - 1].warehouseID
          : inputRows[lastInputRowIndex].warehouseID;
      });
    }
    case TableRefOptions.CUSTOM_PRICES: {
      return createSelector(selectCustomPriceInputRows, inputRows => {
        const currentInputRowIndex = inputRows.findIndex(
          inputRow => inputRow.locationID === currentRowID,
        );
        if (currentInputRowIndex === -1) {
          void notify(
            `Could not find input row for location id: ${currentRowID}`,
          );
        }
        const lastInputRowIndex = inputRows.length - 1;
        return currentInputRowIndex > 0
          ? inputRows[currentInputRowIndex - 1].locationID
          : inputRows[lastInputRowIndex].locationID;
      });
    }
  }
};

export const selectUnsavedChangeExists = createSelector(
  selectItemState,
  itemState => {
    return [
      ...itemState.warehousePriceInputRows,
      ...itemState.customPriceInputRows,
    ].some(inputRow => inputRow.inputRowChanged);
  },
);

export const selectLocationSearchResults = createSelector(
  selectItemState,
  itemState => itemState.customPriceLocationSearchResults,
);

export const selectCustomPriceExists = createSelector(
  selectCustomPriceInputRows,
  customPrices => customPrices.length > 0,
);

export const selectLocationNamesOfCustomPricesToBeDeleted = createSelector(
  selectCustomPriceInputRows,
  customPrices => {
    return customPrices
      .reduce<string[]>((locations, next) => {
        const toBeDeleted = next.prevSaved && next.softDeleted;
        return !toBeDeleted
          ? locations
          : locations.concat([' ' + next.locationName]);
      }, [])
      .toString();
  },
);

export const selectInvalidNullCustomPriceExists = createSelector(
  selectCustomPriceInputRows,
  customPrices =>
    customPrices.some(
      inputRow =>
        !inputRow.softDeleted && inputRow.newCustomPriceCents === null,
    ),
);
