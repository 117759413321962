import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const selectUiState = (state: RootState) => state.ui;

export const selectEditMode = createSelector(
  selectUiState,
  uiState => uiState.editMode,
);

export const selectShowModal = createSelector(
  selectUiState,
  uiState => uiState.showModal,
);
