import ModalStyleWrapper from '../ModalStyleWrapper';
import {
  Close,
  Flex,
  ThemeUICSSObject,
  Heading,
  Input,
  Button,
  Container,
} from 'theme-ui';
import { debounce } from 'lodash';
import { useAppDispatch } from '@/hooks/store';
import { setShowModal } from '@/state/ui';
import {
  addToCustomPriceInputRows,
  clearCustomPriceLocationSearchResults,
  filterSelectedCustomPriceLocationSearchResults,
} from '@/state/item';
import { ChangeEvent, useEffect, useRef } from 'react';
import Search from '@/assets/images/Search.svg';
import { getLocationsFromSearch } from '@/state/item/operations';
import ModalTable from './ModalTable';

const customPricingAddLocationModalStyle: ThemeUICSSObject = {
  borderRadius: '0',
  maxWidth: '800px',
  height: '500px',
};

const contentContainerStyle: ThemeUICSSObject = {
  pt: '3',
  px: '7',
  height: '88%',
};

const modalHeaderStyle: ThemeUICSSObject = {
  height: '28%',
};

const flexContainerStyle: ThemeUICSSObject = {
  justifyContent: 'space-between',
  alignItems: 'center',
  pb: '6',
};

const inputStyle: ThemeUICSSObject = {
  width: '100%',
  borderRadius: '0',
  border: 'none',
  boxShadow: '0px 4px 24px rgba(60, 57, 49, 0.12)',
  fontFamily: 'Roobert',
  background: `url(${Search}) no-repeat`,
  backgroundPosition: '10px 6px',
  boxSizing: 'border-box',
  pl: '8',
  mb: '6',
};

const footerStyle: ThemeUICSSObject = {
  height: '12%',
  flexDirection: 'row-reverse',
  boxShadow: '0px 4px 24px rgba(60, 57, 49, 0.12)',
  alignItems: 'center',
};

const inputPlaceholderText = 'Search by business name';

const CustomPriceAddLocationModal = () => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setShowModal(null));
    dispatch(clearCustomPriceLocationSearchResults());
  };
  const handleContinue = () => {
    dispatch(addToCustomPriceInputRows());
    dispatch(setShowModal(null));
    dispatch(clearCustomPriceLocationSearchResults());
  };

  const debouncedSearch = debounce(async (searchInput: string) => {
    await dispatch(getLocationsFromSearch(searchInput));
  }, 300);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (!value.trim()) {
      dispatch(filterSelectedCustomPriceLocationSearchResults());
    } else {
      void debouncedSearch(value);
    }
  };

  // Focus search input by default when rendering component
  const searchInput = useRef<HTMLInputElement>(null);
  useEffect(() => {
    searchInput.current?.focus();
  }, []);

  return (
    <ModalStyleWrapper modalStyle={customPricingAddLocationModalStyle}>
      <Container sx={contentContainerStyle}>
        <Container sx={modalHeaderStyle}>
          <Flex sx={flexContainerStyle}>
            <Heading as="h4">Add Location</Heading>
            <Close onClick={handleClose} sx={{ cursor: 'pointer' }} />
          </Flex>
          <Input
            sx={inputStyle}
            onChange={handleChange}
            placeholder={inputPlaceholderText}
            ref={searchInput}
          />
        </Container>
        <ModalTable />
      </Container>
      <Flex sx={footerStyle}>
        <Button sx={{ mr: '7' }} onClick={handleContinue} variant="cta_sm">
          Continue
        </Button>
        <Button sx={{ mr: '2' }} onClick={handleClose} variant="btn_icon">
          Cancel
        </Button>
      </Flex>
    </ModalStyleWrapper>
  );
};

export default CustomPriceAddLocationModal;
