import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { setCustomPriceLocationSearchResultSelected } from '@/state/item';
import { selectLocationSearchResults } from '@/state/item/selectors';
import { Checkbox } from '@odekoteam/doppio';
import { Text, ThemeUICSSObject } from 'theme-ui';

const tableDataTextStyle: ThemeUICSSObject = {
  fontFamily: 'Roobert',
  fontWeight: '400',
  fontSize: '14px',
  color: '#615E54',
};

interface ModalTableDataRowProps {
  locationID: string;
  locationName: string;
}

const ModalTableDataRow = ({
  locationID,
  locationName,
}: ModalTableDataRowProps) => {
  const dispatch = useAppDispatch();
  const locationRow = useAppSelector(selectLocationSearchResults).find(
    locationRow => locationRow.locationID === locationID,
  );
  const locationIsSelected =
    locationRow !== undefined ? locationRow.selected : false;

  const handleSelect = () => {
    if (locationRow !== undefined) {
      const payload = {
        locationID,
        selected: !locationIsSelected,
      };
      dispatch(setCustomPriceLocationSearchResultSelected(payload));
    }
  };

  return (
    <tr sx={{ height: '40px' }}>
      <td sx={{ width: '10%', pl: '2' }}>
        <Checkbox isChecked={locationIsSelected} onChange={handleSelect} />
      </td>
      <td sx={{ width: '50%', maxWidth: '360px', overflowWrap: 'break-word' }}>
        <Text sx={tableDataTextStyle}>{locationName}</Text>
      </td>
      <td sx={{ pl: '1' }}>
        <Text sx={tableDataTextStyle}>{locationID}</Text>
      </td>
    </tr>
  );
};

export default ModalTableDataRow;
