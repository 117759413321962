import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { TableRefOptions, WarehousePriceInputRow } from '@/state/item/types';
import { Input, ThemeUICSSObject } from 'theme-ui';
import { ChangeEvent, FocusEvent, KeyboardEvent } from 'react';
import {
  setWarehousePriceInputRowOnBlur,
  setWarehousePriceInputRowOnChange,
} from '@/state/item';
import {
  centsToDollars,
  createChangeEventPayload,
} from '@/utils/CalculatorUtils';
import { selectEditMode } from '@/state/ui/selectors';
import { selectNextRowID, selectPrevRowID } from '@/state/item/selectors';

const numberDataStyle: ThemeUICSSObject = {
  fontFamily: 'SFMono-Regular, monospace',
  fontWeight: '0',
  fontSize: '1',
  lineHeight: '1',
  textAlign: 'right',
  pr: '5',
};

const numberDataLastColumnStyle: ThemeUICSSObject = {
  ...numberDataStyle,
  bg: 'backgroundWeakest',
};

const inputStyle: ThemeUICSSObject = {
  ml: '2',
  borderRadius: '2px',
  borderColor: 'backgroundMediumBorder',
  fontFamily: 'SFMono-Regular',
  fontWeight: '0',
  fontSize: '1',
  lineHeight: '1',
  textAlign: 'right',
};

const inputLastColumnStyle: ThemeUICSSObject = {
  ...inputStyle,
  bg: 'background',
};

interface ItemPricingTableDataRowProps {
  warehouseID: string;
}

const ItemPricingTableDataRow = ({
  warehouseID,
}: ItemPricingTableDataRowProps) => {
  const dispatch = useAppDispatch();
  const editMode = useAppSelector(selectEditMode);
  const nextRowID = useAppSelector(
    selectNextRowID(warehouseID, TableRefOptions.WAREHOUSE_PRICES),
  );
  const prevRowID = useAppSelector(
    selectPrevRowID(warehouseID, TableRefOptions.WAREHOUSE_PRICES),
  );

  // TODO: Refactor this component to receive warehousePriceInputRow props and map those instead
  // Finds the input values that correspond to the passed in warehouseId
  const itemPriceInputRow = useAppSelector(state => {
    const thisItemPriceInput = state.item.warehousePriceInputRows?.find(
      item => item.warehouseID === warehouseID,
    );
    return thisItemPriceInput;
  });

  const {
    warehouseName,
    purchasePriceCents,
    newLandedCost,
    newSalePrice,
    newMarginPercent,
    newMarginDollars,
    currentLandedCostCents,
    currentMarginPercent,
    currentMarginDollars,
    currentSalePriceCents,
  } = itemPriceInputRow as WarehousePriceInputRow;
  const purchasePriceDollars = centsToDollars(purchasePriceCents);
  const currentLandedCostDollars = centsToDollars(currentLandedCostCents);
  const currentSalePriceDollars = centsToDollars(currentSalePriceCents);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const payload = createChangeEventPayload(event, warehouseID);
    dispatch(setWarehousePriceInputRowOnChange(payload));
  };
  const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
    const payload = createChangeEventPayload(event, warehouseID);
    dispatch(setWarehousePriceInputRowOnBlur(payload));
  };
  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const { key, shiftKey, currentTarget } = event;
    if (key === 'Enter') {
      const rowID = shiftKey ? prevRowID : nextRowID;
      const element = document.getElementById(`${rowID}-${currentTarget.name}`);
      element?.focus();
    }
  };

  if (editMode) {
    return (
      <tr sx={{ height: '48px' }}>
        <td sx={{ px: '3' }}>{warehouseName}</td>
        <td sx={numberDataStyle}>{purchasePriceDollars}</td>
        <td sx={numberDataStyle}>
          <Input
            sx={inputStyle}
            value={newLandedCost}
            id={`${warehouseID}-newLandedCost`}
            name="newLandedCost"
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
          />
        </td>
        <td sx={numberDataStyle}>
          <Input
            sx={inputStyle}
            value={newMarginPercent}
            id={`${warehouseID}-newMarginPercent`}
            name="newMarginPercent"
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
          />
        </td>
        <td sx={numberDataStyle}>
          <Input
            sx={inputStyle}
            value={newMarginDollars}
            id={`${warehouseID}-newMarginDollars`}
            name="newMarginDollars"
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
          />
        </td>
        <td sx={numberDataLastColumnStyle}>
          <Input
            sx={inputLastColumnStyle}
            value={newSalePrice}
            id={`${warehouseID}-newSalePrice`}
            name="newSalePrice"
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
          />
        </td>
      </tr>
    );
  }

  return (
    <tr sx={{ height: '48px' }}>
      <td sx={{ px: '3', font: 'text.paragraphMd' }}>{warehouseName}</td>
      <td sx={numberDataStyle}>{purchasePriceDollars}</td>
      <td sx={numberDataStyle}>{currentLandedCostDollars}</td>
      <td sx={numberDataStyle}>{currentMarginPercent}</td>
      <td sx={numberDataStyle}>{currentMarginDollars}</td>
      <td sx={numberDataLastColumnStyle}>{currentSalePriceDollars}</td>
    </tr>
  );
};

export default ItemPricingTableDataRow;
