import {
  Currency,
  InventoryItem,
  Money,
  MoneyInput,
  NonInventoryItem,
  UpdateItemPriceInput,
  UpdateItemPricesInput,
  UpdateLocationPriceInput,
  ItemLocationCustomPrice,
} from '@/generated/graphql-operations';
import {
  CustomPriceInputRow,
  WarehousePriceInputRow,
} from '@/state/item/types';
import {
  calculatePurchasePriceCentsBySalesUnit,
  centsToDollars,
  displayMargin,
  dollarsToCents,
  precisionMarginPercent,
} from './CalculatorUtils';

export const priceOutput = (price?: Money | null): Money => {
  return {
    cents: Number(price?.cents || 0) / 100,
    currency: price?.currency ?? Currency.Usd,
    // TODO_RONY:
    // IMS COMMENT: This field is deprecated and should be removed when we switch to federation V2
    // currencyISO: price?.currency ?? Currency.Usd,
  };
};

export const priceInput = (price?: Money): MoneyInput => {
  if (price) {
    return {
      cents: Math.round(price?.cents * 100),
      // In a future state, we will use next line instead of currencyISO
      // currency: price?.currency ?? Currency.Usd,
      // TODO_RONY:
      // IMS COMMENT: This field is deprecated and should be removed when we switch to federation V2
      // PS COMMENT: Changed currencyISO to currency
      currency: price?.currency ?? Currency.Usd,
    };
  }
  // TODO_RONY:
  // IMS COMMENT:currencyISO is deprecated and should be removed when we switch to federation V2
  // PS COMMENT: Changed currencyISO to currency
  return { cents: 0, currency: Currency.Usd };
};

// TODO: Refactor this to use .reduce instead
export const shapeInitialWarehousePriceInputRows = (
  item: InventoryItem | NonInventoryItem,
): WarehousePriceInputRow[] => {
  const warehouseAssociations = item?.warehouseAssociation || [];
  // Sort warehouseAssociations in alphabetical order by warehouse name
  warehouseAssociations.sort((left, right) => {
    return left && right
      ? left.warehouse.name.localeCompare(right.warehouse.name)
      : 0;
  });
  return warehouseAssociations.map(association => {
    // For inventory items, display purchase price by Sales Unit value
    const purchaseUnitConversionRate =
      item.__typename === 'InventoryItem'
        ? item.purchaseUnit.conversionRate
        : null;
    const purchasePriceCentsBySalesUnit =
      calculatePurchasePriceCentsBySalesUnit(
        item.purchasePrice.cents,
        purchaseUnitConversionRate,
        item.saleUnit.conversionRate,
      );
    // If landed cost is currently null in db, set to value of purchase price.
    const landedCostCents =
      association?.landedCost?.cents ?? purchasePriceCentsBySalesUnit ?? 0;
    const salePriceCents = association?.salePrice?.cents ?? null;
    // If sale price is currently null in db, render an empty input instead of '$0.00'
    const newSalePriceDollars =
      salePriceCents === null ? '' : centsToDollars(salePriceCents);
    const [marginPercent, marginDollars] = displayMargin(
      landedCostCents,
      salePriceCents,
    );
    const newMarginPercentFloat = precisionMarginPercent(
      landedCostCents,
      salePriceCents,
    );

    const row = {
      warehouseID: association?.warehouse?.id ?? '',
      warehouseName: association?.warehouse?.name ?? '',
      itemID: item?.id,
      newLandedCost: centsToDollars(landedCostCents),
      newSalePrice: newSalePriceDollars,
      newMarginPercent: marginPercent,
      newMarginDollars: marginDollars,
      newMarginPercentFloat,
      newMarginCents: dollarsToCents(marginDollars),
      newLandedCostCents: landedCostCents,
      newSalePriceCents: salePriceCents,
      newLandedCostCurrency: association?.landedCost?.currency ?? Currency.Usd,
      newSalePriceCurrency: association?.salePrice?.currency ?? Currency.Usd,
      purchasePriceCents: purchasePriceCentsBySalesUnit,
      currentLandedCostCents: association?.landedCost?.cents ?? null,
      currentLandedCostCurrency: association?.landedCost?.currency ?? null,
      currentMarginPercent: marginPercent,
      currentMarginDollars: marginDollars,
      currentSalePriceCents: association?.salePrice?.cents ?? null,
      currentSalePriceCurrency: association?.salePrice?.currency ?? null,
      inputRowChanged: false,
    };
    return row;
  });
};

export const shapeInitialCustomPriceInputRows = (
  itemLocationsCustomPrices: ItemLocationCustomPrice[],
  itemWarehousePrices: WarehousePriceInputRow[],
): CustomPriceInputRow[] => {
  return itemLocationsCustomPrices
    .reduce<CustomPriceInputRow[]>((customPrices, next) => {
      // Access purchase price and landed cost item state, `warehousePriceInputRows`
      const { purchasePriceCents, newLandedCostCents: landedCostCents } =
        itemWarehousePrices.find(
          row => row.warehouseID === next.location?.warehouse.id,
        ) as WarehousePriceInputRow;
      const [currentMarginPercent, currentMarginDollars] = displayMargin(
        landedCostCents,
        next.customPrice?.cents,
      );
      const newMarginPercentFloat = precisionMarginPercent(
        landedCostCents,
        next.customPrice?.cents ?? null,
      );
      return !next.location
        ? customPrices
        : customPrices.concat([
            {
              locationID: next.location.id,
              locationName: next.location.businessName,
              warehouseID: next.location.warehouse.id,
              warehouseName: next.location.warehouse.name,
              purchasePriceCents,
              landedCostCents,
              currentMarginPercent,
              currentMarginDollars,
              currentCustomPriceCents: next.customPrice?.cents ?? null,
              currentCustomPriceDollars: centsToDollars(
                next.customPrice?.cents,
              ),
              currentCustomPriceCurrency:
                next.customPrice?.currency ?? Currency.Usd,
              newMarginPercent: currentMarginPercent,
              newMarginDollars: currentMarginDollars,
              newMarginPercentFloat,
              newCustomPriceCents: next.customPrice?.cents ?? null,
              newCustomPriceDollars: centsToDollars(next.customPrice?.cents),
              inputRowChanged: false,
              softDeleted: false,
              prevSaved: true,
              priceRuleId: next.priceRuleId as string,
            },
          ]);
    }, [])
    .sort((left, right) => {
      return left && right
        ? left.locationName.localeCompare(right.locationName)
        : 0;
    });
};

export const shapeDataPriceMutation = (
  warehousePriceInputRows: WarehousePriceInputRow[],
  customPriceInputRows: CustomPriceInputRow[],
): UpdateItemPricesInput => {
  const warehousePrices = warehousePriceInputRows.reduce<
    UpdateItemPriceInput[]
  >((prices, next) => {
    const validPriceUpsert =
      next.inputRowChanged && next.newSalePriceCents !== null;
    return !validPriceUpsert
      ? prices
      : prices.concat([
          {
            itemOdekoUuid: next.itemID ?? '',
            newLandedCost: {
              cents: next.newLandedCostCents,
              currencyISO: next.newLandedCostCurrency,
            },
            newSalePrice: {
              cents: next.newSalePriceCents as number,
              currencyISO: next.newSalePriceCurrency,
            },
            previousLandedCost: {
              cents: next.currentLandedCostCents ?? 0,
              currencyISO: next.currentLandedCostCurrency ?? Currency.Usd,
            },
            previousSalePrice: {
              cents: next.currentSalePriceCents ?? 0,
              currencyISO: next.currentSalePriceCurrency ?? Currency.Usd,
            },
            warehouseOdekoUuid: next.warehouseID ?? '',
          },
        ]);
  }, []);

  const customPrices = customPriceInputRows.reduce<UpdateLocationPriceInput[]>(
    (prices, next) => {
      const validPriceUpsert =
        next.inputRowChanged &&
        !next.softDeleted &&
        next.newCustomPriceCents !== null;
      const validDeletion = next.prevSaved && next.softDeleted;
      return !validPriceUpsert && !validDeletion
        ? prices
        : prices.concat([
            {
              itemOdekoUuid: warehousePriceInputRows[0].itemID as string,
              locationOdekoUuid: next.locationID,
              newCustomPrice: next.softDeleted
                ? null
                : {
                    cents: next.newCustomPriceCents as number,
                    currencyISO: next.currentCustomPriceCurrency,
                  },
              previousCustomPrice:
                next.currentCustomPriceCents === null
                  ? null
                  : {
                      cents: next.currentCustomPriceCents,
                      currencyISO: next.currentCustomPriceCurrency,
                    },
            },
          ]);
    },
    [],
  );

  return { prices: warehousePrices, customPrices: customPrices };
};
