import { Container, Spinner, ThemeUICSSObject } from 'theme-ui';
import { useAppSelector } from '@/hooks/store';
import ItemPricingTableHeaderColumn from './ItemPricingTableHeaderColumn';
import ItemPricingTableDataRow from './ItemPricingTableDataRow';
import { selectItemData } from '@/state/item/selectors';

const tableStyle: ThemeUICSSObject = {
  width: '100%',
  color: '#615E54',
  border: '1px solid',
  borderColor: 'secondaryBackgroundDisabled',
  borderCollapse: 'collapse',
};

const tableHeaderStyle: ThemeUICSSObject = {
  verticalAlign: 'top',
  bg: 'backgroundWeak',
};

const thStyle: ThemeUICSSObject = {
  pl: '3',
  width: `${100 / 6}%`,
};

const headerData = [
  {
    rowOneText: 'Warehouse',
    rowTwoText: null,
    questionIcon: false,
    thStyle,
  },
  {
    rowOneText: 'Purchase Price',
    rowTwoText: 'in Sales Unit',
    questionIcon: true,
    thStyle,
  },
  {
    rowOneText: 'Landed Cost',
    rowTwoText: 'in Sales Unit',
    questionIcon: true,
    thStyle,
  },
  {
    rowOneText: 'Margin %',
    rowTwoText: null,
    questionIcon: true,
    thStyle,
  },
  {
    rowOneText: 'Margin $',
    rowTwoText: null,
    questionIcon: true,
    thStyle,
  },
  {
    /* Changed from 'Sale' Price as orignally spec'd to 'Sales' Price due to user feedback, however
     * 'Sale' price variable names to be kept in FE and BE code to avoid breaking changes */
    rowOneText: 'Sales Price',
    rowTwoText: null,
    questionIcon: true,
    thStyle,
  },
];

const ItemPricingTable = () => {
  /* TODO: Reduce these two lines to one line and refactor component to map warehousePriceInputRows instead of warehouseAssociation from response data. */
  const itemResponseData = useAppSelector(selectItemData);
  const warehouseItems = itemResponseData?.warehouseAssociation ?? [];
  // Will be true prior to fulfillment of queryItemById call or if error
  const dataIsLoadingOrUnavailable = warehouseItems.length === 0;

  return (
    <>
      <table sx={tableStyle}>
        <thead sx={{ height: '54px', bg: 'backgroundWeak' }}>
          <tr sx={tableHeaderStyle}>
            {headerData.map((column, idx) => (
              <ItemPricingTableHeaderColumn key={idx} {...column} />
            ))}
          </tr>
        </thead>
        <tbody>
          {warehouseItems.map(warehouseItem => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const { id } = warehouseItem!.warehouse;
            return <ItemPricingTableDataRow key={id} warehouseID={id} />;
          })}
        </tbody>
      </table>
      {dataIsLoadingOrUnavailable && (
        <Container sx={{ textAlign: 'center', my: '4' }}>
          <Spinner />
        </Container>
      )}
    </>
  );
};

export default ItemPricingTable;
